#page-solutions{

    .filter{
        .filter-container{

            display: flex;
            justify-content: center;


            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
            }

            .filter-item{
                cursor: pointer;

                @include media-breakpoint-down(md) {
                    text-align: center;
                }

                &.active{
                    color: $blue;
                }

                &:hover{
                    color: $blue;
                }
            }
        }
    }

    .products{
        .product-item{
            .product-image{
                @include media-breakpoint-down(md) {
                    height: 250px;
                }
            }
        }
    }

    .swiper-pagination{

        position: absolute;
        right: -10%;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-down(md) {
            position: relative;

            display: flex;
            justify-content: center;

            transform: none;

            right: auto;

            margin-top: 50px;
        }

        &:after{
            content: "";
            position: absolute;

            z-index: -1;

            top: 50%;
            left: 50%;

            transform: translate(-50% ,-50%);

            width: 1px;
            height: 100%;

            background-color: $blue;

            @include media-breakpoint-down(md) {
                height: 1px;
                width: 100%;

                left: 0;

                transform: translateY(-50%);
            }

        }

        .swiper-pagination-bullet:not(:last-child){
			margin-bottom: 42px;

            @include media-breakpoint-down(md) {
                margin-right: 42px;
                margin-bottom: 0;
            }

		}

        .swiper-pagination-bullet{
            position: relative;
            display: block;

            width: 14px;
            height: 14px;

            border: 2px solid $blue;
            border-radius: 50%;

            background-color: #FFF !important;
            transition: 1s all;

            opacity: 1;

            &:after{
                content: "";
                position: absolute;

                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%) scale(0);

                width: 52px;
                height: 52px;

                border-radius: 50%;
                border: 2px solid $blue;

                transition: 1s all;
            }


            &-active{
                background-color: $blue !important;

                &:after{
                    transform: translate(-50%, -50%) scale(1);
                }

            }

        }
    }


    .video{
    	#player{
    		width: 100%;
    		height: 740px;
    		@include media-breakpoint-down(md) {
    	        height: 480px;
    	    }
    	}
    	.plyr__poster{
    		background-size: cover;
    	}

        .plyr__video-embed{
            padding-bottom: 0;
            height: 100%;
        }

    	.plyr__control{
    		&.plyr__tab-focus{
    			box-shadow: 0 0 0 0 rgba(26, 175, 255, 0.0) ;
    		}
    		&.plyr__control--overlaid{
    			width: 150px;
    			height: 150px;
    			border: 1px solid $white;
    			background-color: transparent;
    			svg{
    				left: auto;
    				margin: 0 auto;
    			}
    		}
    	}
    	.plyr--stopped.plyr--video {
    	  	.plyr__controls {
    		    opacity: 0;
    		    pointer-events: none;
    		}
    		&.plyr--init-play {
    		    .plyr__controls {
    		      	opacity: 1;
    		      	pointer-events: inherit;
    		    }
    		}
    	}
    }

    .help{
        .help-item{
            border-bottom: 1px solid $blue;

            padding-left: 0;
            padding-right: 0;

            a{
                width: 100%;
            }

            &:first-child{
                border-right: 1px solid $blue;

                @include media-breakpoint-down(md) {
                    border-right: none;
                }
            }

            .help-download, .help-guide{
                cursor: pointer;

                transition: .3s all;

                h3{
                    transition: .3s all;
                }

                &:after{
                    content: "";

                    position: relative;

                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                    height: 32px;
                    width: 32px;

                    margin-left: 20px;

                    transition: .3s all;
                }

                &:hover{
                    background-color: $blue;
                    h3{
                        color: $white;
                    }
                }
            }

            .help-download{
                &:after{
                    background-image: url('../../src/img/icon/download.svg');
                }
                &:hover{
                    &:after{
                        background-image: url('../../src/img/icon/download-white.svg');
                    }
                }
            }
            .help-guide{
                &:after{
                    background-image: url('../../src/img/icon/link.svg');
                }
                &:hover{
                    &:after{
                        background-image: url('../../src/img/icon/link-white.svg');
                    }
                }
            }

        }
    }


}
