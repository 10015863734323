.form {

    .gform_body {
        .gform_fields {

            display: flex;
            flex-wrap: wrap;

        }

        .gfield_required {
            display: none;
        }

        .materialize-form-group {

            position: relative;

            margin-top: 30px;

            label {

                color: $grey;
                font-weight: 300;

                position: absolute;

                bottom: 10px;
                left: 15px;

                margin-bottom: 10px;

            }

            input {
                padding-left: 15px;

                border: 0;
                border-bottom: 1px solid $grey;
            }

        }

        .ginput_container_textarea {

            textarea {
                border: 1px solid $grey;

                padding: 15px;
            }

            textarea::placeholder {
                color: $grey;
            }
        }

        .consent {
            display: flex;

            width: 100%;

            margin-top: 25px;

            .gfield_label {
                display: none;
            }

            .gfield_checkbox li {
                display: flex;
                align-items: center;

                padding: 0 15px;

                position: relative;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }

                input {
                    position: absolute;

                    cursor: pointer;

                    height: 0;
                    width: 0;

                    opacity: 0;
                }

                label {
                    cursor: pointer;

                    padding-left: 50px;

                    width: 50%;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }

                    &:before {
                        content: "";

                        position: absolute;

                        top: 0;

                        height: 24px;
                        width: 24px;

                        transition: all 0.3s ease;

                        border-radius: 4px;
                        border: 1px solid $grey;
                    }

                    &:after {
                        content: "";

                        position: absolute;

                        top: 2px;
                        left: 17px;

                        height: 20px;
                        width: 20px;

                        transition: all 0.3s ease;

                        transform: scale(0);

                        background: $grey;

                        border-radius: 4px;
                    }

                    &:before {
                        left: 15px;
                    }
                }

                input:checked~label::after {
                    transform: scale(1);
                }

                input:checked~label {
                    font-weight: 300;
                }
            }



            .gfield_description {
                border: 0;

                font-size: 12px;

                margin-left: 10px;
                padding: 0;

                width: 50%;
            }

        }
    }

    .gform_footer {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        width: 30%;
        float: right;

        transform: translateY(-150%);

        @include media-breakpoint-down(md) {
            transform: translateY(0);

            justify-content: center;

            width: 100%;
            float: none;
        }

        cursor: pointer;

        &:after {
            content: "";

            background: url('../img/icon/small-right-arrow.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            width: 18px;
            height: 10px;

            margin-left: 25px;

            transition: .3s all;
        }

        &:hover {
            &:after {
                transform: translateX(-10px);
            }
        }

        input[type=submit] {
            -webkit-appearance: none;

            cursor: pointer;

            color: $blue;
            text-transform: uppercase;
            font-weight: 700;

            background: $white;
            border: 0;

            @include media-breakpoint-down(md) {
                margin: 0;
                width: auto;
            }
        }
    }

    .gform_validation_error{

        label{
            transform: translateY(-10px);
            font-size: 12px !important;
        }

    }

    .gfield_error{

        background: transparent !important;
        padding: 0 !important;
        border: 0 !important;
        padding-right: 16px !important;

        label{
            color: red !important;
        }

        input{
            border: 0 !important;
            border-bottom: 1px solid red !important; 
        }

        .validation_message{
            display: none;
        }
    }

    .validation_error{
        display: none;
    }

}