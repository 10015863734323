/*
|--------------------
|      FOOTER
|--------------------
*/

.newsletter-form{
    a{
        display: none;
    }
}