#page-customer-single{

    .client-logo{
        width: 150px;

        img{
            width: 100%;
        }
        @include media-breakpoint-down(md) {
            width: 100px;
        }
    }
    
    .swiper-wrapper{
        height: auto;
    }

    .arrow-pagination{
        display: flex;
        justify-content: center;
        
        @extend .mr-md-child-not-last;

        .slider-btn{
            height: 24px;
            width: 24px;

            position: relative;

            cursor: pointer;

            &:focus{
                outline: none;
            }

            &:before{
                content: "";

                position: absolute;

                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                background-image: url('../img/icon/right-arrow-blue.svg');
                background-size: contain;
                background-position: center;
            }

            &.slider-button-prev{
                transform: rotate(-180deg);
            }
        }

    }


}