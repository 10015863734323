@charset "UTF-8";
/* Bootstrap lightweight */
/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(../../node_modules/fullpage.js/dist/fullpage.min.css);
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  text-align: left; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

p {
  margin-top: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container, .slider-links .swiper-slide .swiper-slide-inner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, .slider-links .swiper-slide .swiper-slide-inner {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, .slider-links .swiper-slide .swiper-slide-inner {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, .slider-links .swiper-slide .swiper-slide-inner {
      max-width: 960px; } }
  @media (min-width: 1300px) {
    .container, .slider-links .swiper-slide .swiper-slide-inner {
      max-width: 1240px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1300px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

/* Function */
/*
|---------------------------------------------------------------
| Functions
|---------------------------------------------------------------
| Functions used to add classes to generation
|
|
*/
/*
|
| Add Buttons
|-----------------------
|
*/
/*
|
| Add Blocks
|-----------------------
|
*/
/*
|
| Add Links
|-----------------------
|
*/
/*
|
| Add Links
|-----------------------
|
*/
/* variables */
/*
|---------------------------------------------------------------
| Variables
|---------------------------------------------------------------
| Import variables
|
|
*/
/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Margins and paddings
|
|
*/
/*
|--------------
| Space sizes
|--------------
| Variables used to generate magins & paddings classes (including responsive)
| - Normal classes: .{property}-{size} / .mb-md / margin-bottom: $md  
| - Responsive classes: .{screen}-{up/down/only}-{property}-{size} / .md-down-mb-md /  (max-width: 992px) { margin-bottom: $md }
|
*/
/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/
/*
|
| Colors declaration
|----------------------
|
*/
/*
|
| Assign colors
|---------------
|
*/
/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/
/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .font-{size}
| - Exemple: .font-md
|
*/
/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
/*
|
| FONT-FAMILIES
|----------------
|
*/
/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes"
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
/*
|
| LINE-HEIGHT
|--------------
|
*/
/*
|
| Default font settings
|------------------------
|
*/
/*
|
| Default titles settings
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| LINKS
|---------------------------------------------------------------
| Generate and assign links colors
|
|
*/
/*
|
| Links default
|----------------
|
*/
/*
|------------------------
| Links colors creation
|------------------------
| - How to use: Add --> $links-colors: map-set-links($map, $index, $color, $color-hover)
| - Generated classes: .link-{color} & .links-{color} (on parent)
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Buttons base default
|----------------------
|
*/
/*
|
| Buttons size setting
|-----------------------
|
*/
/*
|-------------------
| Buttons creation
|-------------------
| - How to use: $button-colors: map-set-buttons($map, $index, $background-color, $border-color, $text-color)
| - Generated classes: .btn-{size}, .btn-{color}, .btn-icon-{direction}.
|
*/
/*
|---------------------------------------------------------------
| SECTIONS
|---------------------------------------------------------------
| Generate responsive sections
|
|
*/
/*
|-------------------
| Sections setting
|-------------------
| - Generated class: .section
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Bg img default
|----------------------
|
*/
/*
|-------------------
| Bg img creation
|-------------------
| - How to use: $bg-imgs: map-set-bg-imgs($map, $index, $map-sizes)
| - Generated classes: .bg-img-{size}
|
*/
/* Components */
/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/
/*
|
| Text Transform
|----------------------------
|
*/
.tt-u {
  text-transform: uppercase !important; }

.tt-l {
  text-transform: lowercase !important; }

.tt-c {
  text-transform: capitalize !important; }

/*
|
| Underline
|------------
|
*/
.td-u {
  text-decoration: underline !important; }

.td-n {
  text-decoration: none !important; }

/*
|
| Font styles
|--------------
|
*/
.fs-i {
  font-style: italic !important; }

.fs-o {
  font-style: oblique !important; }

.fs-n {
  font-style: normal !important; }

/*
|
| Background cover
|-------------------
|
*/
.bg-cover, .bg-cover-top, .bg-cover-bottom, .banner, .custom-card .item-img {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }

.bg-cover-top {
  background-position: center top !important; }

.bg-cover-bottom {
  background-position: center bottom !important; }

/*
|
| Overlays
|-------------
|
*/
.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }
  .overlay + * {
    position: relative;
    z-index: 2; }

/*
|
| Overflows
|-------------
|
*/
.o-v {
  overflow: visible !important; }

.ox-v {
  overflow-x: visible !important; }

.oy-v {
  overflow-y: visible !important; }

.o-h {
  overflow: hidden !important; }

.ox-h {
  overflow-x: hidden !important; }

.oy-h {
  overflow-y: hidden !important; }

/*
|
| Positions
|-------------
|
*/
.p-r {
  position: relative !important; }

.p-a {
  position: absolute !important; }

.p-f {
  position: fixed !important; }

.p-s {
  position: static !important; }

/*
|
| Flex Alignment
|-----------------
|
*/
.ai-start {
  align-items: flex-start; }

.ai-end {
  align-items: flex-end; }

.ai-center {
  align-items: center; }

.ai-baseline {
  align-items: baseline; }

.ai-stretch {
  align-items: stretch; }

/*
|
| Flex Justify
|---------------
|
*/
.jc-start {
  justify-content: flex-start; }

.jc-end {
  justify-content: flex-end; }

.jc-center, #page-global-coverage .filter-container {
  justify-content: center; }

.jc-between {
  justify-content: space-between; }

.jc-around {
  justify-content: space-around; }

.fw-wrap {
  flex-wrap: wrap !important; }

/*
|
| White space
|--------------
|
*/
.ws-inherit {
  white-space: inherit; }

.ws-normal {
  white-space: normal; }

.ws-nowrap {
  white-space: nowrap; }

.ws-pre {
  white-space: pre; }

.ws-pre-wrap {
  white-space: pre-wrap; }

.ws-pre-line {
  white-space: pre-line; }

/*
|
| Pointer events
|-----------------
|
*/
.pe-inherit {
  pointer-events: inherit; }

.pe-none {
  pointer-events: none; }

.pe-auto {
  pointer-events: auto; }

/*
|
| Optimize animation
|---------------------
|
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/* Core */
/*
|---------------------------------------------------------------
| MAPS
|---------------------------------------------------------------
| Sass maps used in classes generation
|
|
*/
/*
|
| Font Sizes Map
|---------------------
|
*/
/*
|
| Title Sizes Map
|---------------------
|
*/
/*
|
| Buttons Sizes Map
|---------------------
|
*/
/*
|
| Margins & Paddings Maps
|--------------------------
|
*/
/*
|-------------------------------
| Font weight classes creation
|-------------------------------
| How to use: add lines to create more "font weight classes"
| - Generated classes: .font-{weight}
| - Exemple: .font-regular
|
*/
/*
|
| Font-weights
|---------------
|
*/
/*
|
| Text Align Map
|--------------------------
|
*/
/*
|-------------------------------
| Line-height classes creation
|-------------------------------
| How to use: add lines to create more "line-height classes"
| - Generated classes: .lh-{weight}
| - Exemple: .lh-sm
|
*/
/*
|
| Letter Spacing Map
|--------------------------
|
*/
/*
|
| Display Map
|--------------------------
|
*/
/*
|
| Section Map
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| MIXINS
|---------------------------------------------------------------
| Mixins called from generator to generate classes
|
|
*/
/*
|
| Commonly used mixins
|-----------------------
|
*/
/*
|
| Margins & Paddings
|-----------------------
|
*/
/*
|
| Responsive Margins & Paddings
|--------------------------------
|
*/
/*
|
| Font-sizes
|-----------------------
|
*/
/*
|
| Titles
|-----------------------
|
*/
/*
|
| Links
|-----------------------
|
*/
/*
|
| Buttons
|-----------------------
|
*/
/*
|
| Hidden classes
|-----------------------
|
*/
/*
|
| Display
|-----------------------
|
*/
/*
|
| Text Align
|-----------------------
|
*/
/*
|
| Sections
|-----------------------
|
*/
/*
|
| Bg imgs
|-------------
|
*/
/*
|---------------------------------------------------------------
| CLASS GENERATOR
|---------------------------------------------------------------
| Mixins calls to generate classes
|
|
*/
/*------- DISPLAY -------*/
@media (max-width: 1299.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1300px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1299.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1300px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1299.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1300px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1299.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1300px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1299.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1300px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1299.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1300px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- FONT-SIZES -------*/
.fs-xs {
  font-size: 12px !important; }
  @media (max-width: 1299.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 991.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 767.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 575.98px) {
    .fs-xs {
      font-size: 12px !important; } }

.fs-sm {
  font-size: 14px !important; }
  @media (max-width: 1299.98px) {
    .fs-sm {
      font-size: 14px !important; } }
  @media (max-width: 991.98px) {
    .fs-sm {
      font-size: 14px !important; } }
  @media (max-width: 767.98px) {
    .fs-sm {
      font-size: 14px !important; } }
  @media (max-width: 575.98px) {
    .fs-sm {
      font-size: 14px !important; } }

.fs-md {
  font-size: 16px !important; }
  @media (max-width: 1299.98px) {
    .fs-md {
      font-size: 16px !important; } }
  @media (max-width: 991.98px) {
    .fs-md {
      font-size: 16px !important; } }
  @media (max-width: 767.98px) {
    .fs-md {
      font-size: 16px !important; } }
  @media (max-width: 575.98px) {
    .fs-md {
      font-size: 16px !important; } }

.fs-lg {
  font-size: 22px !important; }
  @media (max-width: 1299.98px) {
    .fs-lg {
      font-size: 22px !important; } }
  @media (max-width: 991.98px) {
    .fs-lg {
      font-size: 22px !important; } }
  @media (max-width: 767.98px) {
    .fs-lg {
      font-size: 22px !important; } }
  @media (max-width: 575.98px) {
    .fs-lg {
      font-size: 22px !important; } }

.fs-xl {
  font-size: 36px !important; }
  @media (max-width: 1299.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xl {
      font-size: 36px !important; } }

.fs-xxl {
  font-size: 50px !important; }
  @media (max-width: 1299.98px) {
    .fs-xxl {
      font-size: 50px !important; } }
  @media (max-width: 991.98px) {
    .fs-xxl {
      font-size: 50px !important; } }
  @media (max-width: 767.98px) {
    .fs-xxl {
      font-size: 50px !important; } }
  @media (max-width: 575.98px) {
    .fs-xxl {
      font-size: 50px !important; } }

/*------- TITLES -------*/
.title-xs {
  color: #0674B7;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 16px; }
  @media (max-width: 1299.98px) {
    .title-xs {
      font-size: 16px; } }
  @media (max-width: 991.98px) {
    .title-xs {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    .title-xs {
      font-size: 16px; } }
  @media (max-width: 575.98px) {
    .title-xs {
      font-size: 16px; } }

.title-sm {
  color: #0674B7;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 18px; }
  @media (max-width: 1299.98px) {
    .title-sm {
      font-size: 18px; } }
  @media (max-width: 991.98px) {
    .title-sm {
      font-size: 18px; } }
  @media (max-width: 767.98px) {
    .title-sm {
      font-size: 18px; } }
  @media (max-width: 575.98px) {
    .title-sm {
      font-size: 18px; } }

.title-md {
  color: #0674B7;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 20px; }
  @media (max-width: 1299.98px) {
    .title-md {
      font-size: 20px; } }
  @media (max-width: 991.98px) {
    .title-md {
      font-size: 20px; } }
  @media (max-width: 767.98px) {
    .title-md {
      font-size: 20px; } }
  @media (max-width: 575.98px) {
    .title-md {
      font-size: 20px; } }

.title-lg {
  color: #0674B7;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 24px; }
  @media (max-width: 1299.98px) {
    .title-lg {
      font-size: 24px; } }
  @media (max-width: 991.98px) {
    .title-lg {
      font-size: 24px; } }
  @media (max-width: 767.98px) {
    .title-lg {
      font-size: 24px; } }
  @media (max-width: 575.98px) {
    .title-lg {
      font-size: 18px; } }

.title-xl {
  color: #0674B7;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 30px; }
  @media (max-width: 1299.98px) {
    .title-xl {
      font-size: 30px; } }
  @media (max-width: 991.98px) {
    .title-xl {
      font-size: 30px; } }
  @media (max-width: 767.98px) {
    .title-xl {
      font-size: 30px; } }
  @media (max-width: 575.98px) {
    .title-xl {
      font-size: 24px; } }

.title-xxl {
  color: #0674B7;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 64px; }
  @media (max-width: 1299.98px) {
    .title-xxl {
      font-size: 64px; } }
  @media (max-width: 991.98px) {
    .title-xxl {
      font-size: 44px; } }
  @media (max-width: 767.98px) {
    .title-xxl {
      font-size: 44px; } }
  @media (max-width: 575.98px) {
    .title-xxl {
      font-size: 44px; } }

/*------- FONT-FAMILY -------*/
.ff-default {
  font-family: "Roboto", sans-serif ; }

.ff-custom {
  font-family: "Gotham", sans-serif ; }

/*------- FONT-WEIGHTS -------*/
.fw-100 {
  font-weight: 100 ; }

.fw-200 {
  font-weight: 200 ; }

.fw-300 {
  font-weight: 300 ; }

.fw-400 {
  font-weight: 400 ; }

.fw-500 {
  font-weight: 500 ; }

.fw-600 {
  font-weight: 600 ; }

.fw-700 {
  font-weight: 700 ; }

.fw-800 {
  font-weight: 800 ; }

.fw-900 {
  font-weight: 900 ; }

/*------- LINE-HEIGHTS -------*/
.lh-xs {
  line-height: 1 ; }

.lh-sm {
  line-height: 1.2 ; }

.lh-md {
  line-height: 1.5 ; }

.lh-lg {
  line-height: 1.6 ; }

.lh-xl {
  line-height: 1.875 ; }

.lh-xxl {
  line-height: 2 ; }

/*------- COLORS -------*/
.c-black {
  color: #000000 ; }

.c-very-dark-grey {
  color: #212121 ; }

.c-dark-grey {
  color: #424242 ; }

.c-grey {
  color: rgba(146, 146, 146, 0.8) ; }

.c-light-grey {
  color: #F7F7F7 ; }

.c-very-light-grey {
  color: #fafafa ; }

.c-white {
  color: #FFFFFF ; }

.c-blue {
  color: #0674B7 ; }

.c-border-grey {
  color: #D9D9D9 ; }

.c-blue-opacity {
  color: rgba(6, 116, 183, 0.5) ; }

/*------- LINKS -------*/
.link-white {
  color: #FFFFFF; }
  .link-white:hover {
    color: #F7F7F7; }

.links-white a {
  color: #FFFFFF; }
  .links-white a:hover {
    color: #F7F7F7; }

.link-black {
  color: #000000; }
  .link-black:hover {
    color: rgba(146, 146, 146, 0.8); }

.links-black a {
  color: #000000; }
  .links-black a:hover {
    color: rgba(146, 146, 146, 0.8); }

/*------- BUTTONS -------*/
.btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg, .newsletter-form .gform_footer input {
  position: relative;
  font-family: "Gotham", sans-serif;
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  background: #FFFFFF;
  color: #0674B7;
  border: 1px solid #D9D9D9;
  border-radius: 0;
  transition: all 0.3s;
  box-shadow: none !important;
  white-space: inherit;
  cursor: pointer;
  text-transform: uppercase; }
  .btn:hover, .btn-black:hover, .btn-white:hover, .btn-xs:hover, .btn-sm:hover, .btn-md:hover, .btn-lg:hover, .newsletter-form .gform_footer input:hover {
    color: #0674B7; }
  .btn .icon, .btn-black .icon, .btn-white .icon, .btn-xs .icon, .btn-sm .icon, .btn-md .icon, .btn-lg .icon, .newsletter-form .gform_footer input .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn .icon svg, .btn-black .icon svg, .btn-white .icon svg, .btn-xs .icon svg, .btn-sm .icon svg, .btn-md .icon svg, .btn-lg .icon svg, .newsletter-form .gform_footer input .icon svg, .btn .icon img, .btn-black .icon img, .btn-white .icon img, .btn-xs .icon img, .btn-sm .icon img, .btn-md .icon img, .btn-lg .icon img, .newsletter-form .gform_footer input .icon img {
      width: 100%;
      max-height: 100%; }
  .btn.btn-icon-right, .btn-icon-right.btn-black, .btn-icon-right.btn-white, .btn-icon-right.btn-xs, .btn-icon-right.btn-sm, .btn-icon-right.btn-md, .btn-icon-right.btn-lg, .newsletter-form .gform_footer input.btn-icon-right {
    text-align: right;
    padding-right: 55px; }
    .btn.btn-icon-right .icon, .btn-icon-right.btn-black .icon, .btn-icon-right.btn-white .icon, .btn-icon-right.btn-xs .icon, .btn-icon-right.btn-sm .icon, .btn-icon-right.btn-md .icon, .btn-icon-right.btn-lg .icon, .newsletter-form .gform_footer input.btn-icon-right .icon {
      right: 15px; }
  .btn.btn-icon-left, .btn-icon-left.btn-black, .btn-icon-left.btn-white, .btn-icon-left.btn-xs, .btn-icon-left.btn-sm, .btn-icon-left.btn-md, .btn-icon-left.btn-lg, .newsletter-form .gform_footer input.btn-icon-left {
    text-align: left;
    padding-left: 55px; }
    .btn.btn-icon-left .icon, .btn-icon-left.btn-black .icon, .btn-icon-left.btn-white .icon, .btn-icon-left.btn-xs .icon, .btn-icon-left.btn-sm .icon, .btn-icon-left.btn-md .icon, .btn-icon-left.btn-lg .icon, .newsletter-form .gform_footer input.btn-icon-left .icon {
      left: 15px; }

.btn-black {
  background-color: #000000;
  border-color: #000000;
  color: #FFFFFF; }
  .btn-black:hover {
    color: #FFFFFF; }
  .btn-black .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-black .icon svg, .btn-black .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-black.btn-icon-right {
    text-align: right;
    padding-right: 55px; }
    .btn-black.btn-icon-right .icon {
      right: 15px; }
  .btn-black.btn-icon-left {
    text-align: left;
    padding-left: 55px; }
    .btn-black.btn-icon-left .icon {
      left: 15px; }

.btn-white {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #000000; }
  .btn-white:hover {
    color: #000000; }
  .btn-white .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-white .icon svg, .btn-white .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-white.btn-icon-right {
    text-align: right;
    padding-right: 55px; }
    .btn-white.btn-icon-right .icon {
      right: 15px; }
  .btn-white.btn-icon-left {
    text-align: left;
    padding-left: 55px; }
    .btn-white.btn-icon-left .icon {
      left: 15px; }

.btn-xs {
  padding: 2px 5px;
  font-size: 10px; }
  .btn-xs .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 10px; }
    .btn-xs .icon svg, .btn-xs .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-xs.btn-icon-right {
    text-align: right;
    padding-right: 25px; }
    .btn-xs.btn-icon-right .icon {
      right: 5px; }
  .btn-xs.btn-icon-left {
    text-align: left;
    padding-left: 25px; }
    .btn-xs.btn-icon-left .icon {
      left: 5px; }

.btn-sm {
  padding: 5px 10px;
  font-size: 14px; }
  .btn-sm .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 20px; }
    .btn-sm .icon svg, .btn-sm .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-sm.btn-icon-right {
    text-align: right;
    padding-right: 40px; }
    .btn-sm.btn-icon-right .icon {
      right: 10px; }
  .btn-sm.btn-icon-left {
    text-align: left;
    padding-left: 40px; }
    .btn-sm.btn-icon-left .icon {
      left: 10px; }

.btn-md {
  padding: 10px 20px;
  font-size: 16px; }
  .btn-md .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-md .icon svg, .btn-md .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-md.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-md.btn-icon-right .icon {
      right: 20px; }
  .btn-md.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-md.btn-icon-left .icon {
      left: 20px; }

.btn-lg {
  padding: 20px 30px;
  font-size: 20px; }
  .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 50px; }
    .btn-lg .icon svg, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-lg.btn-icon-right {
    text-align: right;
    padding-right: 90px; }
    .btn-lg.btn-icon-right .icon {
      right: 30px; }
  .btn-lg.btn-icon-left {
    text-align: left;
    padding-left: 90px; }
    .btn-lg.btn-icon-left .icon {
      left: 30px; }

/*------- BACKGROUND-COLORS -------*/
.bg-black {
  background-color: #000000 ; }

.bg-very-dark-grey {
  background-color: #212121 ; }

.bg-dark-grey {
  background-color: #424242 ; }

.bg-grey {
  background-color: rgba(146, 146, 146, 0.8) ; }

.bg-light-grey {
  background-color: #F7F7F7 ; }

.bg-very-light-grey {
  background-color: #fafafa ; }

.bg-white {
  background-color: #FFFFFF ; }

.bg-blue {
  background-color: #0674B7 ; }

.bg-border-grey {
  background-color: #D9D9D9 ; }

.bg-blue-opacity {
  background-color: rgba(6, 116, 183, 0.5) ; }

/*------- TEXT-ALIGN -------*/
.ta-r {
  text-align: right ; }

.ta-l {
  text-align: left ; }

.ta-c {
  text-align: center ; }

.ta-j {
  text-align: justify ; }

@media (max-width: 1299.98px) {
  .lg-down-ta-r {
    text-align: right; } }

@media (max-width: 991.98px) {
  .md-down-ta-r {
    text-align: right; } }

@media (max-width: 767.98px) {
  .sm-down-ta-r {
    text-align: right; } }

@media (max-width: 575.98px) {
  .xs-down-ta-r {
    text-align: right; } }

.xs-up-ta-r {
  text-align: right; }

@media (min-width: 576px) {
  .sm-up-ta-r {
    text-align: right; } }

@media (min-width: 768px) {
  .md-up-ta-r {
    text-align: right; } }

@media (min-width: 992px) {
  .lg-up-ta-r {
    text-align: right; } }

@media (min-width: 1300px) {
  .xl-up-ta-r {
    text-align: right; } }

@media (max-width: 1299.98px) {
  .lg-down-ta-l {
    text-align: left; } }

@media (max-width: 991.98px) {
  .md-down-ta-l {
    text-align: left; } }

@media (max-width: 767.98px) {
  .sm-down-ta-l {
    text-align: left; } }

@media (max-width: 575.98px) {
  .xs-down-ta-l {
    text-align: left; } }

.xs-up-ta-l {
  text-align: left; }

@media (min-width: 576px) {
  .sm-up-ta-l {
    text-align: left; } }

@media (min-width: 768px) {
  .md-up-ta-l {
    text-align: left; } }

@media (min-width: 992px) {
  .lg-up-ta-l {
    text-align: left; } }

@media (min-width: 1300px) {
  .xl-up-ta-l {
    text-align: left; } }

@media (max-width: 1299.98px) {
  .lg-down-ta-c {
    text-align: center; } }

@media (max-width: 991.98px) {
  .md-down-ta-c {
    text-align: center; } }

@media (max-width: 767.98px) {
  .sm-down-ta-c {
    text-align: center; } }

@media (max-width: 575.98px) {
  .xs-down-ta-c {
    text-align: center; } }

.xs-up-ta-c {
  text-align: center; }

@media (min-width: 576px) {
  .sm-up-ta-c {
    text-align: center; } }

@media (min-width: 768px) {
  .md-up-ta-c {
    text-align: center; } }

@media (min-width: 992px) {
  .lg-up-ta-c {
    text-align: center; } }

@media (min-width: 1300px) {
  .xl-up-ta-c {
    text-align: center; } }

@media (max-width: 1299.98px) {
  .lg-down-ta-j {
    text-align: justify; } }

@media (max-width: 991.98px) {
  .md-down-ta-j {
    text-align: justify; } }

@media (max-width: 767.98px) {
  .sm-down-ta-j {
    text-align: justify; } }

@media (max-width: 575.98px) {
  .xs-down-ta-j {
    text-align: justify; } }

.xs-up-ta-j {
  text-align: justify; }

@media (min-width: 576px) {
  .sm-up-ta-j {
    text-align: justify; } }

@media (min-width: 768px) {
  .md-up-ta-j {
    text-align: justify; } }

@media (min-width: 992px) {
  .lg-up-ta-j {
    text-align: justify; } }

@media (min-width: 1300px) {
  .xl-up-ta-j {
    text-align: justify; } }

/*------- LETTER SPACING -------*/
.ls-xs {
  letter-spacing: 1px ; }

.ls-sm {
  letter-spacing: 2px ; }

.ls-md {
  letter-spacing: 3px ; }

.ls-lg {
  letter-spacing: 4px ; }

.ls-xl {
  letter-spacing: 10px ; }

.ls-xxl {
  letter-spacing: 15px ; }

/*------- DISPLAYS -------*/
.d-none {
  display: none ; }

.d-i {
  display: inline ; }

.d-b {
  display: block ; }

.d-ib {
  display: inline-block ; }

.d-f, #page-global-coverage .filter-container {
  display: flex ; }

.d-if {
  display: inline-flex ; }

@media (max-width: 1299.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1300px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1299.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1300px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1299.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1300px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1299.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1300px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1299.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1300px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1299.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1300px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- SECTIONS -------*/
.section {
  position: relative;
  padding-top: 120px !important;
  padding-bottom: 120px !important; }
  @media (max-width: 1299.98px) {
    .section {
      padding-top: 120px !important;
      padding-bottom: 120px !important; } }
  @media (max-width: 991.98px) {
    .section {
      padding-top: 50px !important;
      padding-bottom: 50px !important; } }
  @media (max-width: 767.98px) {
    .section {
      padding-top: 50px !important;
      padding-bottom: 50px !important; } }
  @media (max-width: 575.98px) {
    .section {
      padding-top: 50px !important;
      padding-bottom: 50px !important; } }

/*------- BG IMG -------*/
.bg-img, .bg-img-lg, .bg-img-full, .bg-img-md {
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.bg-img-lg {
  height: 900px; }
  @media (max-width: 1299.98px) {
    .bg-img-lg {
      height: 800px; } }
  @media (max-width: 991.98px) {
    .bg-img-lg {
      height: 70vh; } }
  @media (max-width: 767.98px) {
    .bg-img-lg {
      height: 80vh; } }
  @media (max-width: 575.98px) {
    .bg-img-lg {
      height: 100vh; } }

.bg-img-full {
  height: 100%; }
  @media (max-width: 1299.98px) {
    .bg-img-full {
      height: 100%; } }
  @media (max-width: 991.98px) {
    .bg-img-full {
      height: 400px; } }
  @media (max-width: 767.98px) {
    .bg-img-full {
      height: 400px; } }
  @media (max-width: 575.98px) {
    .bg-img-full {
      height: 400px; } }

.bg-img-md {
  height: 600px; }
  @media (max-width: 1299.98px) {
    .bg-img-md {
      height: 400px; } }
  @media (max-width: 991.98px) {
    .bg-img-md {
      height: 300px; } }
  @media (max-width: 767.98px) {
    .bg-img-md {
      height: 300px; } }
  @media (max-width: 575.98px) {
    .bg-img-md {
      height: 300px; } }

/*------- MARGINS & PADDINGS -------*/
.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs {
  margin: 5px !important; }

.mt-xs {
  margin-top: 5px !important; }

.mb-xs {
  margin-bottom: 5px !important; }

.my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs {
  margin-left: 5px !important; }

.mr-xs {
  margin-right: 5px !important; }

.mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs {
  padding: 5px !important; }

.pt-xs {
  padding-top: 5px !important; }

.pb-xs {
  padding-bottom: 5px !important; }

.py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs {
  padding-left: 5px !important; }

.pr-xs {
  padding-right: 5px !important; }

.px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm {
  margin: 15px !important; }

.mt-sm {
  margin-top: 15px !important; }

.mb-sm {
  margin-bottom: 15px !important; }

.my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm {
  margin-left: 15px !important; }

.mr-sm {
  margin-right: 15px !important; }

.mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm {
  padding: 15px !important; }

.pt-sm {
  padding-top: 15px !important; }

.pb-sm {
  padding-bottom: 15px !important; }

.py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm {
  padding-left: 15px !important; }

.pr-sm {
  padding-right: 15px !important; }

.px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md {
  margin: 30px !important; }

.mt-md {
  margin-top: 30px !important; }

.mb-md {
  margin-bottom: 30px !important; }

.my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md {
  margin-left: 30px !important; }

.mr-md {
  margin-right: 30px !important; }

.mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md {
  padding: 30px !important; }

.pt-md {
  padding-top: 30px !important; }

.pb-md {
  padding-bottom: 30px !important; }

.py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md {
  padding-left: 30px !important; }

.pr-md {
  padding-right: 30px !important; }

.px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg {
  margin: 50px !important; }

.mt-lg {
  margin-top: 50px !important; }

.mb-lg {
  margin-bottom: 50px !important; }

.my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg {
  margin-left: 50px !important; }

.mr-lg {
  margin-right: 50px !important; }

.mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg {
  padding: 50px !important; }

.pt-lg {
  padding-top: 50px !important; }

.pb-lg {
  padding-bottom: 50px !important; }

.py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg {
  padding-left: 50px !important; }

.pr-lg {
  padding-right: 50px !important; }

.px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl {
  margin: 80px !important; }

.mt-xl {
  margin-top: 80px !important; }

.mb-xl {
  margin-bottom: 80px !important; }

.my-xl {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.ml-xl {
  margin-left: 80px !important; }

.mr-xl {
  margin-right: 80px !important; }

.mx-xl {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.p-xl {
  padding: 80px !important; }

.pt-xl {
  padding-top: 80px !important; }

.pb-xl {
  padding-bottom: 80px !important; }

.py-xl {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.pl-xl {
  padding-left: 80px !important; }

.pr-xl {
  padding-right: 80px !important; }

.px-xl {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.m-xxl {
  margin: 120px !important; }

.mt-xxl {
  margin-top: 120px !important; }

.mb-xxl {
  margin-bottom: 120px !important; }

.my-xxl {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }

.ml-xxl {
  margin-left: 120px !important; }

.mr-xxl {
  margin-right: 120px !important; }

.mx-xxl {
  margin-left: 120px !important;
  margin-right: 120px !important; }

.p-xxl {
  padding: 120px !important; }

.pt-xxl {
  padding-top: 120px !important; }

.pb-xxl {
  padding-bottom: 120px !important; }

.py-xxl {
  padding-top: 120px !important;
  padding-bottom: 120px !important; }

.pl-xxl {
  padding-left: 120px !important; }

.pr-xxl {
  padding-right: 120px !important; }

.px-xxl {
  padding-left: 120px !important;
  padding-right: 120px !important; }

.m-0-child > * {
  margin: 0 !important; }

.m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.mt-0-child > * {
  margin-top: 0 !important; }

.mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.mb-0-child > * {
  margin-bottom: 0 !important; }

.mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0-child > * {
  margin-left: 0 !important; }

.ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.mr-0-child > * {
  margin-right: 0 !important; }

.mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0-child > * {
  padding: 0 !important; }

.p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.pt-0-child > * {
  padding-top: 0 !important; }

.pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.pb-0-child > * {
  padding-bottom: 0 !important; }

.pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0-child > * {
  padding-left: 0 !important; }

.pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.pr-0-child > * {
  padding-right: 0 !important; }

.pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs-child > * {
  margin: 5px !important; }

.m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.mt-xs-child > * {
  margin-top: 5px !important; }

.mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.mb-xs-child > * {
  margin-bottom: 5px !important; }

.mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs-child > * {
  margin-left: 5px !important; }

.ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.mr-xs-child > * {
  margin-right: 5px !important; }

.mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs-child > * {
  padding: 5px !important; }

.p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.pt-xs-child > * {
  padding-top: 5px !important; }

.pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.pb-xs-child > * {
  padding-bottom: 5px !important; }

.pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs-child > * {
  padding-left: 5px !important; }

.pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.pr-xs-child > * {
  padding-right: 5px !important; }

.pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm-child > * {
  margin: 15px !important; }

.m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.mt-sm-child > * {
  margin-top: 15px !important; }

.mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.mb-sm-child > * {
  margin-bottom: 15px !important; }

.mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm-child > * {
  margin-left: 15px !important; }

.ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.mr-sm-child > * {
  margin-right: 15px !important; }

.mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm-child > * {
  padding: 15px !important; }

.p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.pt-sm-child > * {
  padding-top: 15px !important; }

.pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.pb-sm-child > * {
  padding-bottom: 15px !important; }

.pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm-child > * {
  padding-left: 15px !important; }

.pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.pr-sm-child > * {
  padding-right: 15px !important; }

.pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md-child > * {
  margin: 30px !important; }

.m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.mt-md-child > * {
  margin-top: 30px !important; }

.mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.mb-md-child > * {
  margin-bottom: 30px !important; }

.mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md-child > * {
  margin-left: 30px !important; }

.ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.mr-md-child > * {
  margin-right: 30px !important; }

.mr-md-child-not-last > *:not(:last-child), #page-customer-single .arrow-pagination > *:not(:last-child) {
  margin-right: 30px !important; }

.mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md-child > * {
  padding: 30px !important; }

.p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.pt-md-child > * {
  padding-top: 30px !important; }

.pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.pb-md-child > * {
  padding-bottom: 30px !important; }

.pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md-child > * {
  padding-left: 30px !important; }

.pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.pr-md-child > * {
  padding-right: 30px !important; }

.pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg-child > * {
  margin: 50px !important; }

.m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.mt-lg-child > * {
  margin-top: 50px !important; }

.mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.mb-lg-child > * {
  margin-bottom: 50px !important; }

.mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg-child > * {
  margin-left: 50px !important; }

.ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.mr-lg-child > * {
  margin-right: 50px !important; }

.mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg-child > * {
  padding: 50px !important; }

.p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.pt-lg-child > * {
  padding-top: 50px !important; }

.pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.pb-lg-child > * {
  padding-bottom: 50px !important; }

.pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg-child > * {
  padding-left: 50px !important; }

.pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.pr-lg-child > * {
  padding-right: 50px !important; }

.pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl-child > * {
  margin: 80px !important; }

.m-xl-child-not-last > *:not(:last-child) {
  margin: 80px !important; }

.mt-xl-child > * {
  margin-top: 80px !important; }

.mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 80px !important; }

.mb-xl-child > * {
  margin-bottom: 80px !important; }

.mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 80px !important; }

.my-xl-child > * {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.my-xl-child-not-last > *:not(:last-child) {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.ml-xl-child > * {
  margin-left: 80px !important; }

.ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 80px !important; }

.mr-xl-child > * {
  margin-right: 80px !important; }

.mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 80px !important; }

.mx-xl-child > * {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.p-xl-child > * {
  padding: 80px !important; }

.p-xl-child-not-last > *:not(:last-child) {
  padding: 80px !important; }

.pt-xl-child > * {
  padding-top: 80px !important; }

.pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 80px !important; }

.pb-xl-child > * {
  padding-bottom: 80px !important; }

.pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 80px !important; }

.py-xl-child > * {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.py-xl-child-not-last > *:not(:last-child) {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.pl-xl-child > * {
  padding-left: 80px !important; }

.pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 80px !important; }

.pr-xl-child > * {
  padding-right: 80px !important; }

.pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 80px !important; }

.px-xl-child > * {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.px-xl-child-not-last > *:not(:last-child) {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.m-xxl-child > * {
  margin: 120px !important; }

.m-xxl-child-not-last > *:not(:last-child) {
  margin: 120px !important; }

.mt-xxl-child > * {
  margin-top: 120px !important; }

.mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 120px !important; }

.mb-xxl-child > * {
  margin-bottom: 120px !important; }

.mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 120px !important; }

.my-xxl-child > * {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }

.my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }

.ml-xxl-child > * {
  margin-left: 120px !important; }

.ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 120px !important; }

.mr-xxl-child > * {
  margin-right: 120px !important; }

.mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 120px !important; }

.mx-xxl-child > * {
  margin-left: 120px !important;
  margin-right: 120px !important; }

.mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 120px !important;
  margin-right: 120px !important; }

.p-xxl-child > * {
  padding: 120px !important; }

.p-xxl-child-not-last > *:not(:last-child) {
  padding: 120px !important; }

.pt-xxl-child > * {
  padding-top: 120px !important; }

.pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 120px !important; }

.pb-xxl-child > * {
  padding-bottom: 120px !important; }

.pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 120px !important; }

.py-xxl-child > * {
  padding-top: 120px !important;
  padding-bottom: 120px !important; }

.py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 120px !important;
  padding-bottom: 120px !important; }

.pl-xxl-child > * {
  padding-left: 120px !important; }

.pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 120px !important; }

.pr-xxl-child > * {
  padding-right: 120px !important; }

.pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 120px !important; }

.px-xxl-child > * {
  padding-left: 120px !important;
  padding-right: 120px !important; }

.px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 120px !important;
  padding-right: 120px !important; }

@media (max-width: 1299.98px) {
  .lg-down-m-0 {
    margin: 0 !important; }
  .lg-down-m-0-child > * {
    margin: 0 !important; }
  .lg-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mt-0 {
    margin-top: 0 !important; }
  .lg-down-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mb-0 {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-ml-0 {
    margin-left: 0 !important; }
  .lg-down-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mr-0 {
    margin-right: 0 !important; }
  .lg-down-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-p-0 {
    padding: 0 !important; }
  .lg-down-p-0-child > * {
    padding: 0 !important; }
  .lg-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pt-0 {
    padding-top: 0 !important; }
  .lg-down-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pb-0 {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pl-0 {
    padding-left: 0 !important; }
  .lg-down-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pr-0 {
    padding-right: 0 !important; }
  .lg-down-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 1299.98px) {
  .lg-down-m-xs {
    margin: 5px !important; }
  .lg-down-m-xs-child > * {
    margin: 5px !important; }
  .lg-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mt-xs {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mb-xs {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-ml-xs {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mr-xs {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-p-xs {
    padding: 5px !important; }
  .lg-down-p-xs-child > * {
    padding: 5px !important; }
  .lg-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pt-xs {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pb-xs {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pl-xs {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pr-xs {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-m-sm {
    margin: 15px !important; }
  .lg-down-m-sm-child > * {
    margin: 15px !important; }
  .lg-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mt-sm {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mb-sm {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-ml-sm {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mr-sm {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-p-sm {
    padding: 15px !important; }
  .lg-down-p-sm-child > * {
    padding: 15px !important; }
  .lg-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pt-sm {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pb-sm {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pl-sm {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pr-sm {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-m-md {
    margin: 30px !important; }
  .lg-down-m-md-child > * {
    margin: 30px !important; }
  .lg-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mt-md {
    margin-top: 30px !important; }
  .lg-down-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mb-md {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-ml-md {
    margin-left: 30px !important; }
  .lg-down-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mr-md {
    margin-right: 30px !important; }
  .lg-down-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-p-md {
    padding: 30px !important; }
  .lg-down-p-md-child > * {
    padding: 30px !important; }
  .lg-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pt-md {
    padding-top: 30px !important; }
  .lg-down-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pb-md {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pl-md {
    padding-left: 30px !important; }
  .lg-down-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pr-md {
    padding-right: 30px !important; }
  .lg-down-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-m-lg {
    margin: 50px !important; }
  .lg-down-m-lg-child > * {
    margin: 50px !important; }
  .lg-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mt-lg {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mb-lg {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-ml-lg {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mr-lg {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-p-lg {
    padding: 50px !important; }
  .lg-down-p-lg-child > * {
    padding: 50px !important; }
  .lg-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pt-lg {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pb-lg {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pl-lg {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pr-lg {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-m-xl {
    margin: 80px !important; }
  .lg-down-m-xl-child > * {
    margin: 80px !important; }
  .lg-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mt-xl {
    margin-top: 80px !important; }
  .lg-down-mt-xl-child > * {
    margin-top: 80px !important; }
  .lg-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mb-xl {
    margin-bottom: 80px !important; }
  .lg-down-mb-xl-child > * {
    margin-bottom: 80px !important; }
  .lg-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-my-xl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .lg-down-my-xl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .lg-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-ml-xl {
    margin-left: 80px !important; }
  .lg-down-ml-xl-child > * {
    margin-left: 80px !important; }
  .lg-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mr-xl {
    margin-right: 80px !important; }
  .lg-down-mr-xl-child > * {
    margin-right: 80px !important; }
  .lg-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mx-xl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .lg-down-mx-xl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .lg-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-p-xl {
    padding: 80px !important; }
  .lg-down-p-xl-child > * {
    padding: 80px !important; }
  .lg-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pt-xl {
    padding-top: 80px !important; }
  .lg-down-pt-xl-child > * {
    padding-top: 80px !important; }
  .lg-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pb-xl {
    padding-bottom: 80px !important; }
  .lg-down-pb-xl-child > * {
    padding-bottom: 80px !important; }
  .lg-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-py-xl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .lg-down-py-xl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .lg-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pl-xl {
    padding-left: 80px !important; }
  .lg-down-pl-xl-child > * {
    padding-left: 80px !important; }
  .lg-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pr-xl {
    padding-right: 80px !important; }
  .lg-down-pr-xl-child > * {
    padding-right: 80px !important; }
  .lg-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-px-xl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .lg-down-px-xl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .lg-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-m-xxl {
    margin: 120px !important; }
  .lg-down-m-xxl-child > * {
    margin: 120px !important; }
  .lg-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mt-xxl {
    margin-top: 120px !important; }
  .lg-down-mt-xxl-child > * {
    margin-top: 120px !important; }
  .lg-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mb-xxl {
    margin-bottom: 120px !important; }
  .lg-down-mb-xxl-child > * {
    margin-bottom: 120px !important; }
  .lg-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-my-xxl {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .lg-down-my-xxl-child > * {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .lg-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important;
    margin-bottom: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-ml-xxl {
    margin-left: 120px !important; }
  .lg-down-ml-xxl-child > * {
    margin-left: 120px !important; }
  .lg-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mr-xxl {
    margin-right: 120px !important; }
  .lg-down-mr-xxl-child > * {
    margin-right: 120px !important; }
  .lg-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-mx-xxl {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .lg-down-mx-xxl-child > * {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .lg-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important;
    margin-right: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-p-xxl {
    padding: 120px !important; }
  .lg-down-p-xxl-child > * {
    padding: 120px !important; }
  .lg-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pt-xxl {
    padding-top: 120px !important; }
  .lg-down-pt-xxl-child > * {
    padding-top: 120px !important; }
  .lg-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pb-xxl {
    padding-bottom: 120px !important; }
  .lg-down-pb-xxl-child > * {
    padding-bottom: 120px !important; }
  .lg-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-py-xxl {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .lg-down-py-xxl-child > * {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .lg-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important;
    padding-bottom: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pl-xxl {
    padding-left: 120px !important; }
  .lg-down-pl-xxl-child > * {
    padding-left: 120px !important; }
  .lg-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-pr-xxl {
    padding-right: 120px !important; }
  .lg-down-pr-xxl-child > * {
    padding-right: 120px !important; }
  .lg-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 120px !important; } }

@media (max-width: 1299.98px) {
  .lg-down-px-xxl {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .lg-down-px-xxl-child > * {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .lg-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-0 {
    margin: 0 !important; }
  .md-down-m-0-child > * {
    margin: 0 !important; }
  .md-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-0 {
    margin-top: 0 !important; }
  .md-down-mt-0-child > * {
    margin-top: 0 !important; }
  .md-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-0 {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-0 {
    margin-left: 0 !important; }
  .md-down-ml-0-child > * {
    margin-left: 0 !important; }
  .md-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-0 {
    margin-right: 0 !important; }
  .md-down-mr-0-child > * {
    margin-right: 0 !important; }
  .md-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-p-0 {
    padding: 0 !important; }
  .md-down-p-0-child > * {
    padding: 0 !important; }
  .md-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-0 {
    padding-top: 0 !important; }
  .md-down-pt-0-child > * {
    padding-top: 0 !important; }
  .md-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-0 {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-0 {
    padding-left: 0 !important; }
  .md-down-pl-0-child > * {
    padding-left: 0 !important; }
  .md-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-0 {
    padding-right: 0 !important; }
  .md-down-pr-0-child > * {
    padding-right: 0 !important; }
  .md-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xs {
    margin: 5px !important; }
  .md-down-m-xs-child > * {
    margin: 5px !important; }
  .md-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xs {
    margin-top: 5px !important; }
  .md-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xs {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xs {
    margin-left: 5px !important; }
  .md-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xs {
    margin-right: 5px !important; }
  .md-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xs {
    padding: 5px !important; }
  .md-down-p-xs-child > * {
    padding: 5px !important; }
  .md-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xs {
    padding-top: 5px !important; }
  .md-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xs {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xs {
    padding-left: 5px !important; }
  .md-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xs {
    padding-right: 5px !important; }
  .md-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-sm {
    margin: 15px !important; }
  .md-down-m-sm-child > * {
    margin: 15px !important; }
  .md-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-sm {
    margin-top: 15px !important; }
  .md-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-sm {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-sm {
    margin-left: 15px !important; }
  .md-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-sm {
    margin-right: 15px !important; }
  .md-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-sm {
    padding: 15px !important; }
  .md-down-p-sm-child > * {
    padding: 15px !important; }
  .md-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-sm {
    padding-top: 15px !important; }
  .md-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-sm {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-sm {
    padding-left: 15px !important; }
  .md-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-sm {
    padding-right: 15px !important; }
  .md-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-md {
    margin: 30px !important; }
  .md-down-m-md-child > * {
    margin: 30px !important; }
  .md-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-md {
    margin-top: 30px !important; }
  .md-down-mt-md-child > * {
    margin-top: 30px !important; }
  .md-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-md {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-md {
    margin-left: 30px !important; }
  .md-down-ml-md-child > * {
    margin-left: 30px !important; }
  .md-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-md {
    margin-right: 30px !important; }
  .md-down-mr-md-child > * {
    margin-right: 30px !important; }
  .md-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-md {
    padding: 30px !important; }
  .md-down-p-md-child > * {
    padding: 30px !important; }
  .md-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-md {
    padding-top: 30px !important; }
  .md-down-pt-md-child > * {
    padding-top: 30px !important; }
  .md-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-md {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-md {
    padding-left: 30px !important; }
  .md-down-pl-md-child > * {
    padding-left: 30px !important; }
  .md-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-md {
    padding-right: 30px !important; }
  .md-down-pr-md-child > * {
    padding-right: 30px !important; }
  .md-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-lg {
    margin: 50px !important; }
  .md-down-m-lg-child > * {
    margin: 50px !important; }
  .md-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-lg {
    margin-top: 50px !important; }
  .md-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-lg {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-lg {
    margin-left: 50px !important; }
  .md-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-lg {
    margin-right: 50px !important; }
  .md-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-lg {
    padding: 50px !important; }
  .md-down-p-lg-child > * {
    padding: 50px !important; }
  .md-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-lg {
    padding-top: 50px !important; }
  .md-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-lg {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-lg {
    padding-left: 50px !important; }
  .md-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-lg {
    padding-right: 50px !important; }
  .md-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xl {
    margin: 80px !important; }
  .md-down-m-xl-child > * {
    margin: 80px !important; }
  .md-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xl {
    margin-top: 80px !important; }
  .md-down-mt-xl-child > * {
    margin-top: 80px !important; }
  .md-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xl {
    margin-bottom: 80px !important; }
  .md-down-mb-xl-child > * {
    margin-bottom: 80px !important; }
  .md-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .md-down-my-xl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .md-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xl {
    margin-left: 80px !important; }
  .md-down-ml-xl-child > * {
    margin-left: 80px !important; }
  .md-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xl {
    margin-right: 80px !important; }
  .md-down-mr-xl-child > * {
    margin-right: 80px !important; }
  .md-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .md-down-mx-xl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .md-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xl {
    padding: 80px !important; }
  .md-down-p-xl-child > * {
    padding: 80px !important; }
  .md-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xl {
    padding-top: 80px !important; }
  .md-down-pt-xl-child > * {
    padding-top: 80px !important; }
  .md-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xl {
    padding-bottom: 80px !important; }
  .md-down-pb-xl-child > * {
    padding-bottom: 80px !important; }
  .md-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .md-down-py-xl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .md-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xl {
    padding-left: 80px !important; }
  .md-down-pl-xl-child > * {
    padding-left: 80px !important; }
  .md-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xl {
    padding-right: 80px !important; }
  .md-down-pr-xl-child > * {
    padding-right: 80px !important; }
  .md-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .md-down-px-xl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .md-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xxl {
    margin: 120px !important; }
  .md-down-m-xxl-child > * {
    margin: 120px !important; }
  .md-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xxl {
    margin-top: 120px !important; }
  .md-down-mt-xxl-child > * {
    margin-top: 120px !important; }
  .md-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xxl {
    margin-bottom: 120px !important; }
  .md-down-mb-xxl-child > * {
    margin-bottom: 120px !important; }
  .md-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xxl {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .md-down-my-xxl-child > * {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .md-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important;
    margin-bottom: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xxl {
    margin-left: 120px !important; }
  .md-down-ml-xxl-child > * {
    margin-left: 120px !important; }
  .md-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xxl {
    margin-right: 120px !important; }
  .md-down-mr-xxl-child > * {
    margin-right: 120px !important; }
  .md-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xxl {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .md-down-mx-xxl-child > * {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .md-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important;
    margin-right: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xxl {
    padding: 120px !important; }
  .md-down-p-xxl-child > * {
    padding: 120px !important; }
  .md-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xxl {
    padding-top: 120px !important; }
  .md-down-pt-xxl-child > * {
    padding-top: 120px !important; }
  .md-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xxl {
    padding-bottom: 120px !important; }
  .md-down-pb-xxl-child > * {
    padding-bottom: 120px !important; }
  .md-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xxl {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .md-down-py-xxl-child > * {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .md-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important;
    padding-bottom: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xxl {
    padding-left: 120px !important; }
  .md-down-pl-xxl-child > * {
    padding-left: 120px !important; }
  .md-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xxl {
    padding-right: 120px !important; }
  .md-down-pr-xxl-child > * {
    padding-right: 120px !important; }
  .md-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 120px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xxl {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .md-down-px-xxl-child > * {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .md-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-0 {
    margin: 0 !important; }
  .sm-down-m-0-child > * {
    margin: 0 !important; }
  .sm-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-0 {
    margin-top: 0 !important; }
  .sm-down-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-0 {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-0 {
    margin-left: 0 !important; }
  .sm-down-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-0 {
    margin-right: 0 !important; }
  .sm-down-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-0 {
    padding: 0 !important; }
  .sm-down-p-0-child > * {
    padding: 0 !important; }
  .sm-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-0 {
    padding-top: 0 !important; }
  .sm-down-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-0 {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-0 {
    padding-left: 0 !important; }
  .sm-down-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-0 {
    padding-right: 0 !important; }
  .sm-down-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xs {
    margin: 5px !important; }
  .sm-down-m-xs-child > * {
    margin: 5px !important; }
  .sm-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xs {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xs {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xs {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xs {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xs {
    padding: 5px !important; }
  .sm-down-p-xs-child > * {
    padding: 5px !important; }
  .sm-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xs {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xs {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xs {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xs {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-sm {
    margin: 15px !important; }
  .sm-down-m-sm-child > * {
    margin: 15px !important; }
  .sm-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-sm {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-sm {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-sm {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-sm {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-sm {
    padding: 15px !important; }
  .sm-down-p-sm-child > * {
    padding: 15px !important; }
  .sm-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-sm {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-sm {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-sm {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-sm {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-md {
    margin: 30px !important; }
  .sm-down-m-md-child > * {
    margin: 30px !important; }
  .sm-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-md {
    margin-top: 30px !important; }
  .sm-down-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-md {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-md {
    margin-left: 30px !important; }
  .sm-down-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-md {
    margin-right: 30px !important; }
  .sm-down-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-md {
    padding: 30px !important; }
  .sm-down-p-md-child > * {
    padding: 30px !important; }
  .sm-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-md {
    padding-top: 30px !important; }
  .sm-down-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-md {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-md {
    padding-left: 30px !important; }
  .sm-down-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-md {
    padding-right: 30px !important; }
  .sm-down-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-lg {
    margin: 50px !important; }
  .sm-down-m-lg-child > * {
    margin: 50px !important; }
  .sm-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-lg {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-lg {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-lg {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-lg {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-lg {
    padding: 50px !important; }
  .sm-down-p-lg-child > * {
    padding: 50px !important; }
  .sm-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-lg {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-lg {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-lg {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-lg {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xl {
    margin: 80px !important; }
  .sm-down-m-xl-child > * {
    margin: 80px !important; }
  .sm-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xl {
    margin-top: 80px !important; }
  .sm-down-mt-xl-child > * {
    margin-top: 80px !important; }
  .sm-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xl {
    margin-bottom: 80px !important; }
  .sm-down-mb-xl-child > * {
    margin-bottom: 80px !important; }
  .sm-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .sm-down-my-xl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .sm-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xl {
    margin-left: 80px !important; }
  .sm-down-ml-xl-child > * {
    margin-left: 80px !important; }
  .sm-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xl {
    margin-right: 80px !important; }
  .sm-down-mr-xl-child > * {
    margin-right: 80px !important; }
  .sm-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .sm-down-mx-xl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .sm-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xl {
    padding: 80px !important; }
  .sm-down-p-xl-child > * {
    padding: 80px !important; }
  .sm-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xl {
    padding-top: 80px !important; }
  .sm-down-pt-xl-child > * {
    padding-top: 80px !important; }
  .sm-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xl {
    padding-bottom: 80px !important; }
  .sm-down-pb-xl-child > * {
    padding-bottom: 80px !important; }
  .sm-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .sm-down-py-xl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .sm-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xl {
    padding-left: 80px !important; }
  .sm-down-pl-xl-child > * {
    padding-left: 80px !important; }
  .sm-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xl {
    padding-right: 80px !important; }
  .sm-down-pr-xl-child > * {
    padding-right: 80px !important; }
  .sm-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .sm-down-px-xl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .sm-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xxl {
    margin: 120px !important; }
  .sm-down-m-xxl-child > * {
    margin: 120px !important; }
  .sm-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xxl {
    margin-top: 120px !important; }
  .sm-down-mt-xxl-child > * {
    margin-top: 120px !important; }
  .sm-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xxl {
    margin-bottom: 120px !important; }
  .sm-down-mb-xxl-child > * {
    margin-bottom: 120px !important; }
  .sm-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xxl {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .sm-down-my-xxl-child > * {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .sm-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important;
    margin-bottom: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xxl {
    margin-left: 120px !important; }
  .sm-down-ml-xxl-child > * {
    margin-left: 120px !important; }
  .sm-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xxl {
    margin-right: 120px !important; }
  .sm-down-mr-xxl-child > * {
    margin-right: 120px !important; }
  .sm-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xxl {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .sm-down-mx-xxl-child > * {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .sm-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important;
    margin-right: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xxl {
    padding: 120px !important; }
  .sm-down-p-xxl-child > * {
    padding: 120px !important; }
  .sm-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xxl {
    padding-top: 120px !important; }
  .sm-down-pt-xxl-child > * {
    padding-top: 120px !important; }
  .sm-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xxl {
    padding-bottom: 120px !important; }
  .sm-down-pb-xxl-child > * {
    padding-bottom: 120px !important; }
  .sm-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xxl {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .sm-down-py-xxl-child > * {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .sm-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important;
    padding-bottom: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xxl {
    padding-left: 120px !important; }
  .sm-down-pl-xxl-child > * {
    padding-left: 120px !important; }
  .sm-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xxl {
    padding-right: 120px !important; }
  .sm-down-pr-xxl-child > * {
    padding-right: 120px !important; }
  .sm-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 120px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xxl {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .sm-down-px-xxl-child > * {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .sm-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-0 {
    margin: 0 !important; }
  .xs-down-m-0-child > * {
    margin: 0 !important; }
  .xs-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-0 {
    margin-top: 0 !important; }
  .xs-down-mt-0-child > * {
    margin-top: 0 !important; }
  .xs-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-0 {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-0 {
    margin-left: 0 !important; }
  .xs-down-ml-0-child > * {
    margin-left: 0 !important; }
  .xs-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-0 {
    margin-right: 0 !important; }
  .xs-down-mr-0-child > * {
    margin-right: 0 !important; }
  .xs-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-0 {
    padding: 0 !important; }
  .xs-down-p-0-child > * {
    padding: 0 !important; }
  .xs-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-0 {
    padding-top: 0 !important; }
  .xs-down-pt-0-child > * {
    padding-top: 0 !important; }
  .xs-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-0 {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-0 {
    padding-left: 0 !important; }
  .xs-down-pl-0-child > * {
    padding-left: 0 !important; }
  .xs-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-0 {
    padding-right: 0 !important; }
  .xs-down-pr-0-child > * {
    padding-right: 0 !important; }
  .xs-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xs {
    margin: 5px !important; }
  .xs-down-m-xs-child > * {
    margin: 5px !important; }
  .xs-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xs {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xs {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xs {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xs {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xs {
    padding: 5px !important; }
  .xs-down-p-xs-child > * {
    padding: 5px !important; }
  .xs-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xs {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xs {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xs {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xs {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-sm {
    margin: 15px !important; }
  .xs-down-m-sm-child > * {
    margin: 15px !important; }
  .xs-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-sm {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-sm {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-sm {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-sm {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-sm {
    padding: 15px !important; }
  .xs-down-p-sm-child > * {
    padding: 15px !important; }
  .xs-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-sm {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-sm {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-sm {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-sm {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-md {
    margin: 30px !important; }
  .xs-down-m-md-child > * {
    margin: 30px !important; }
  .xs-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-md {
    margin-top: 30px !important; }
  .xs-down-mt-md-child > * {
    margin-top: 30px !important; }
  .xs-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-md {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-md {
    margin-left: 30px !important; }
  .xs-down-ml-md-child > * {
    margin-left: 30px !important; }
  .xs-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-md {
    margin-right: 30px !important; }
  .xs-down-mr-md-child > * {
    margin-right: 30px !important; }
  .xs-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-md {
    padding: 30px !important; }
  .xs-down-p-md-child > * {
    padding: 30px !important; }
  .xs-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-md {
    padding-top: 30px !important; }
  .xs-down-pt-md-child > * {
    padding-top: 30px !important; }
  .xs-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-md {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-md {
    padding-left: 30px !important; }
  .xs-down-pl-md-child > * {
    padding-left: 30px !important; }
  .xs-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-md {
    padding-right: 30px !important; }
  .xs-down-pr-md-child > * {
    padding-right: 30px !important; }
  .xs-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-lg {
    margin: 50px !important; }
  .xs-down-m-lg-child > * {
    margin: 50px !important; }
  .xs-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-lg {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-lg {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-lg {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-lg {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-lg {
    padding: 50px !important; }
  .xs-down-p-lg-child > * {
    padding: 50px !important; }
  .xs-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-lg {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-lg {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-lg {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-lg {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xl {
    margin: 80px !important; }
  .xs-down-m-xl-child > * {
    margin: 80px !important; }
  .xs-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xl {
    margin-top: 80px !important; }
  .xs-down-mt-xl-child > * {
    margin-top: 80px !important; }
  .xs-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xl {
    margin-bottom: 80px !important; }
  .xs-down-mb-xl-child > * {
    margin-bottom: 80px !important; }
  .xs-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .xs-down-my-xl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .xs-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xl {
    margin-left: 80px !important; }
  .xs-down-ml-xl-child > * {
    margin-left: 80px !important; }
  .xs-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xl {
    margin-right: 80px !important; }
  .xs-down-mr-xl-child > * {
    margin-right: 80px !important; }
  .xs-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .xs-down-mx-xl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .xs-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xl {
    padding: 80px !important; }
  .xs-down-p-xl-child > * {
    padding: 80px !important; }
  .xs-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xl {
    padding-top: 80px !important; }
  .xs-down-pt-xl-child > * {
    padding-top: 80px !important; }
  .xs-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xl {
    padding-bottom: 80px !important; }
  .xs-down-pb-xl-child > * {
    padding-bottom: 80px !important; }
  .xs-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .xs-down-py-xl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .xs-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xl {
    padding-left: 80px !important; }
  .xs-down-pl-xl-child > * {
    padding-left: 80px !important; }
  .xs-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xl {
    padding-right: 80px !important; }
  .xs-down-pr-xl-child > * {
    padding-right: 80px !important; }
  .xs-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .xs-down-px-xl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .xs-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xxl {
    margin: 120px !important; }
  .xs-down-m-xxl-child > * {
    margin: 120px !important; }
  .xs-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xxl {
    margin-top: 120px !important; }
  .xs-down-mt-xxl-child > * {
    margin-top: 120px !important; }
  .xs-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xxl {
    margin-bottom: 120px !important; }
  .xs-down-mb-xxl-child > * {
    margin-bottom: 120px !important; }
  .xs-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xxl {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .xs-down-my-xxl-child > * {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .xs-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important;
    margin-bottom: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xxl {
    margin-left: 120px !important; }
  .xs-down-ml-xxl-child > * {
    margin-left: 120px !important; }
  .xs-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xxl {
    margin-right: 120px !important; }
  .xs-down-mr-xxl-child > * {
    margin-right: 120px !important; }
  .xs-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xxl {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .xs-down-mx-xxl-child > * {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .xs-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important;
    margin-right: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xxl {
    padding: 120px !important; }
  .xs-down-p-xxl-child > * {
    padding: 120px !important; }
  .xs-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xxl {
    padding-top: 120px !important; }
  .xs-down-pt-xxl-child > * {
    padding-top: 120px !important; }
  .xs-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xxl {
    padding-bottom: 120px !important; }
  .xs-down-pb-xxl-child > * {
    padding-bottom: 120px !important; }
  .xs-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xxl {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .xs-down-py-xxl-child > * {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .xs-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important;
    padding-bottom: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xxl {
    padding-left: 120px !important; }
  .xs-down-pl-xxl-child > * {
    padding-left: 120px !important; }
  .xs-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xxl {
    padding-right: 120px !important; }
  .xs-down-pr-xxl-child > * {
    padding-right: 120px !important; }
  .xs-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 120px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xxl {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .xs-down-px-xxl-child > * {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .xs-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

.xs-up-m-0 {
  margin: 0 !important; }

.xs-up-m-0-child > * {
  margin: 0 !important; }

.xs-up-m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.xs-up-mt-0 {
  margin-top: 0 !important; }

.xs-up-mt-0-child > * {
  margin-top: 0 !important; }

.xs-up-mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.xs-up-mb-0 {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child > * {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.xs-up-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-ml-0 {
  margin-left: 0 !important; }

.xs-up-ml-0-child > * {
  margin-left: 0 !important; }

.xs-up-ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.xs-up-mr-0 {
  margin-right: 0 !important; }

.xs-up-mr-0-child > * {
  margin-right: 0 !important; }

.xs-up-mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.xs-up-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-p-0 {
  padding: 0 !important; }

.xs-up-p-0-child > * {
  padding: 0 !important; }

.xs-up-p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.xs-up-pt-0 {
  padding-top: 0 !important; }

.xs-up-pt-0-child > * {
  padding-top: 0 !important; }

.xs-up-pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.xs-up-pb-0 {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child > * {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.xs-up-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-pl-0 {
  padding-left: 0 !important; }

.xs-up-pl-0-child > * {
  padding-left: 0 !important; }

.xs-up-pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.xs-up-pr-0 {
  padding-right: 0 !important; }

.xs-up-pr-0-child > * {
  padding-right: 0 !important; }

.xs-up-pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.xs-up-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-m-xs {
  margin: 5px !important; }

.xs-up-m-xs-child > * {
  margin: 5px !important; }

.xs-up-m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.xs-up-mt-xs {
  margin-top: 5px !important; }

.xs-up-mt-xs-child > * {
  margin-top: 5px !important; }

.xs-up-mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.xs-up-mb-xs {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child > * {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.xs-up-my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-ml-xs {
  margin-left: 5px !important; }

.xs-up-ml-xs-child > * {
  margin-left: 5px !important; }

.xs-up-ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.xs-up-mr-xs {
  margin-right: 5px !important; }

.xs-up-mr-xs-child > * {
  margin-right: 5px !important; }

.xs-up-mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.xs-up-mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-p-xs {
  padding: 5px !important; }

.xs-up-p-xs-child > * {
  padding: 5px !important; }

.xs-up-p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.xs-up-pt-xs {
  padding-top: 5px !important; }

.xs-up-pt-xs-child > * {
  padding-top: 5px !important; }

.xs-up-pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.xs-up-pb-xs {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child > * {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.xs-up-py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-pl-xs {
  padding-left: 5px !important; }

.xs-up-pl-xs-child > * {
  padding-left: 5px !important; }

.xs-up-pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.xs-up-pr-xs {
  padding-right: 5px !important; }

.xs-up-pr-xs-child > * {
  padding-right: 5px !important; }

.xs-up-pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.xs-up-px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-m-sm {
  margin: 15px !important; }

.xs-up-m-sm-child > * {
  margin: 15px !important; }

.xs-up-m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.xs-up-mt-sm {
  margin-top: 15px !important; }

.xs-up-mt-sm-child > * {
  margin-top: 15px !important; }

.xs-up-mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.xs-up-mb-sm {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child > * {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.xs-up-my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-ml-sm {
  margin-left: 15px !important; }

.xs-up-ml-sm-child > * {
  margin-left: 15px !important; }

.xs-up-ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.xs-up-mr-sm {
  margin-right: 15px !important; }

.xs-up-mr-sm-child > * {
  margin-right: 15px !important; }

.xs-up-mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.xs-up-mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-p-sm {
  padding: 15px !important; }

.xs-up-p-sm-child > * {
  padding: 15px !important; }

.xs-up-p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.xs-up-pt-sm {
  padding-top: 15px !important; }

.xs-up-pt-sm-child > * {
  padding-top: 15px !important; }

.xs-up-pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.xs-up-pb-sm {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child > * {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.xs-up-py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-pl-sm {
  padding-left: 15px !important; }

.xs-up-pl-sm-child > * {
  padding-left: 15px !important; }

.xs-up-pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.xs-up-pr-sm {
  padding-right: 15px !important; }

.xs-up-pr-sm-child > * {
  padding-right: 15px !important; }

.xs-up-pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.xs-up-px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-m-md {
  margin: 30px !important; }

.xs-up-m-md-child > * {
  margin: 30px !important; }

.xs-up-m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.xs-up-mt-md {
  margin-top: 30px !important; }

.xs-up-mt-md-child > * {
  margin-top: 30px !important; }

.xs-up-mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.xs-up-mb-md {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child > * {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.xs-up-my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-ml-md {
  margin-left: 30px !important; }

.xs-up-ml-md-child > * {
  margin-left: 30px !important; }

.xs-up-ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.xs-up-mr-md {
  margin-right: 30px !important; }

.xs-up-mr-md-child > * {
  margin-right: 30px !important; }

.xs-up-mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.xs-up-mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-p-md {
  padding: 30px !important; }

.xs-up-p-md-child > * {
  padding: 30px !important; }

.xs-up-p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.xs-up-pt-md {
  padding-top: 30px !important; }

.xs-up-pt-md-child > * {
  padding-top: 30px !important; }

.xs-up-pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.xs-up-pb-md {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child > * {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.xs-up-py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-pl-md {
  padding-left: 30px !important; }

.xs-up-pl-md-child > * {
  padding-left: 30px !important; }

.xs-up-pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.xs-up-pr-md {
  padding-right: 30px !important; }

.xs-up-pr-md-child > * {
  padding-right: 30px !important; }

.xs-up-pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.xs-up-px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-m-lg {
  margin: 50px !important; }

.xs-up-m-lg-child > * {
  margin: 50px !important; }

.xs-up-m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.xs-up-mt-lg {
  margin-top: 50px !important; }

.xs-up-mt-lg-child > * {
  margin-top: 50px !important; }

.xs-up-mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.xs-up-mb-lg {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child > * {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.xs-up-my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-ml-lg {
  margin-left: 50px !important; }

.xs-up-ml-lg-child > * {
  margin-left: 50px !important; }

.xs-up-ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.xs-up-mr-lg {
  margin-right: 50px !important; }

.xs-up-mr-lg-child > * {
  margin-right: 50px !important; }

.xs-up-mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.xs-up-mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-p-lg {
  padding: 50px !important; }

.xs-up-p-lg-child > * {
  padding: 50px !important; }

.xs-up-p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.xs-up-pt-lg {
  padding-top: 50px !important; }

.xs-up-pt-lg-child > * {
  padding-top: 50px !important; }

.xs-up-pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.xs-up-pb-lg {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child > * {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.xs-up-py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-pl-lg {
  padding-left: 50px !important; }

.xs-up-pl-lg-child > * {
  padding-left: 50px !important; }

.xs-up-pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.xs-up-pr-lg {
  padding-right: 50px !important; }

.xs-up-pr-lg-child > * {
  padding-right: 50px !important; }

.xs-up-pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.xs-up-px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-m-xl {
  margin: 80px !important; }

.xs-up-m-xl-child > * {
  margin: 80px !important; }

.xs-up-m-xl-child-not-last > *:not(:last-child) {
  margin: 80px !important; }

.xs-up-mt-xl {
  margin-top: 80px !important; }

.xs-up-mt-xl-child > * {
  margin-top: 80px !important; }

.xs-up-mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 80px !important; }

.xs-up-mb-xl {
  margin-bottom: 80px !important; }

.xs-up-mb-xl-child > * {
  margin-bottom: 80px !important; }

.xs-up-mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 80px !important; }

.xs-up-my-xl {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.xs-up-my-xl-child > * {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.xs-up-my-xl-child-not-last > *:not(:last-child) {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.xs-up-ml-xl {
  margin-left: 80px !important; }

.xs-up-ml-xl-child > * {
  margin-left: 80px !important; }

.xs-up-ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 80px !important; }

.xs-up-mr-xl {
  margin-right: 80px !important; }

.xs-up-mr-xl-child > * {
  margin-right: 80px !important; }

.xs-up-mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 80px !important; }

.xs-up-mx-xl {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.xs-up-mx-xl-child > * {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.xs-up-mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.xs-up-p-xl {
  padding: 80px !important; }

.xs-up-p-xl-child > * {
  padding: 80px !important; }

.xs-up-p-xl-child-not-last > *:not(:last-child) {
  padding: 80px !important; }

.xs-up-pt-xl {
  padding-top: 80px !important; }

.xs-up-pt-xl-child > * {
  padding-top: 80px !important; }

.xs-up-pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 80px !important; }

.xs-up-pb-xl {
  padding-bottom: 80px !important; }

.xs-up-pb-xl-child > * {
  padding-bottom: 80px !important; }

.xs-up-pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 80px !important; }

.xs-up-py-xl {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.xs-up-py-xl-child > * {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.xs-up-py-xl-child-not-last > *:not(:last-child) {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.xs-up-pl-xl {
  padding-left: 80px !important; }

.xs-up-pl-xl-child > * {
  padding-left: 80px !important; }

.xs-up-pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 80px !important; }

.xs-up-pr-xl {
  padding-right: 80px !important; }

.xs-up-pr-xl-child > * {
  padding-right: 80px !important; }

.xs-up-pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 80px !important; }

.xs-up-px-xl {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.xs-up-px-xl-child > * {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.xs-up-px-xl-child-not-last > *:not(:last-child) {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.xs-up-m-xxl {
  margin: 120px !important; }

.xs-up-m-xxl-child > * {
  margin: 120px !important; }

.xs-up-m-xxl-child-not-last > *:not(:last-child) {
  margin: 120px !important; }

.xs-up-mt-xxl {
  margin-top: 120px !important; }

.xs-up-mt-xxl-child > * {
  margin-top: 120px !important; }

.xs-up-mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 120px !important; }

.xs-up-mb-xxl {
  margin-bottom: 120px !important; }

.xs-up-mb-xxl-child > * {
  margin-bottom: 120px !important; }

.xs-up-mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 120px !important; }

.xs-up-my-xxl {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }

.xs-up-my-xxl-child > * {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }

.xs-up-my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }

.xs-up-ml-xxl {
  margin-left: 120px !important; }

.xs-up-ml-xxl-child > * {
  margin-left: 120px !important; }

.xs-up-ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 120px !important; }

.xs-up-mr-xxl {
  margin-right: 120px !important; }

.xs-up-mr-xxl-child > * {
  margin-right: 120px !important; }

.xs-up-mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 120px !important; }

.xs-up-mx-xxl {
  margin-left: 120px !important;
  margin-right: 120px !important; }

.xs-up-mx-xxl-child > * {
  margin-left: 120px !important;
  margin-right: 120px !important; }

.xs-up-mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 120px !important;
  margin-right: 120px !important; }

.xs-up-p-xxl {
  padding: 120px !important; }

.xs-up-p-xxl-child > * {
  padding: 120px !important; }

.xs-up-p-xxl-child-not-last > *:not(:last-child) {
  padding: 120px !important; }

.xs-up-pt-xxl {
  padding-top: 120px !important; }

.xs-up-pt-xxl-child > * {
  padding-top: 120px !important; }

.xs-up-pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 120px !important; }

.xs-up-pb-xxl {
  padding-bottom: 120px !important; }

.xs-up-pb-xxl-child > * {
  padding-bottom: 120px !important; }

.xs-up-pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 120px !important; }

.xs-up-py-xxl {
  padding-top: 120px !important;
  padding-bottom: 120px !important; }

.xs-up-py-xxl-child > * {
  padding-top: 120px !important;
  padding-bottom: 120px !important; }

.xs-up-py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 120px !important;
  padding-bottom: 120px !important; }

.xs-up-pl-xxl {
  padding-left: 120px !important; }

.xs-up-pl-xxl-child > * {
  padding-left: 120px !important; }

.xs-up-pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 120px !important; }

.xs-up-pr-xxl {
  padding-right: 120px !important; }

.xs-up-pr-xxl-child > * {
  padding-right: 120px !important; }

.xs-up-pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 120px !important; }

.xs-up-px-xxl {
  padding-left: 120px !important;
  padding-right: 120px !important; }

.xs-up-px-xxl-child > * {
  padding-left: 120px !important;
  padding-right: 120px !important; }

.xs-up-px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 120px !important;
  padding-right: 120px !important; }

@media (min-width: 576px) {
  .sm-up-m-0 {
    margin: 0 !important; }
  .sm-up-m-0-child > * {
    margin: 0 !important; }
  .sm-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mt-0 {
    margin-top: 0 !important; }
  .sm-up-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mb-0 {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-ml-0 {
    margin-left: 0 !important; }
  .sm-up-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mr-0 {
    margin-right: 0 !important; }
  .sm-up-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-p-0 {
    padding: 0 !important; }
  .sm-up-p-0-child > * {
    padding: 0 !important; }
  .sm-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pt-0 {
    padding-top: 0 !important; }
  .sm-up-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pb-0 {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pl-0 {
    padding-left: 0 !important; }
  .sm-up-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pr-0 {
    padding-right: 0 !important; }
  .sm-up-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-m-xs {
    margin: 5px !important; }
  .sm-up-m-xs-child > * {
    margin: 5px !important; }
  .sm-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xs {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xs {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xs {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xs {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xs {
    padding: 5px !important; }
  .sm-up-p-xs-child > * {
    padding: 5px !important; }
  .sm-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xs {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xs {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xs {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xs {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-m-sm {
    margin: 15px !important; }
  .sm-up-m-sm-child > * {
    margin: 15px !important; }
  .sm-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-sm {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-sm {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-sm {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-sm {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-p-sm {
    padding: 15px !important; }
  .sm-up-p-sm-child > * {
    padding: 15px !important; }
  .sm-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-sm {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-sm {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-sm {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-sm {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-m-md {
    margin: 30px !important; }
  .sm-up-m-md-child > * {
    margin: 30px !important; }
  .sm-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-md {
    margin-top: 30px !important; }
  .sm-up-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-md {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-md {
    margin-left: 30px !important; }
  .sm-up-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-md {
    margin-right: 30px !important; }
  .sm-up-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-p-md {
    padding: 30px !important; }
  .sm-up-p-md-child > * {
    padding: 30px !important; }
  .sm-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-md {
    padding-top: 30px !important; }
  .sm-up-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-md {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-md {
    padding-left: 30px !important; }
  .sm-up-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-md {
    padding-right: 30px !important; }
  .sm-up-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-m-lg {
    margin: 50px !important; }
  .sm-up-m-lg-child > * {
    margin: 50px !important; }
  .sm-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-lg {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-lg {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-lg {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-lg {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-p-lg {
    padding: 50px !important; }
  .sm-up-p-lg-child > * {
    padding: 50px !important; }
  .sm-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-lg {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-lg {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-lg {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-lg {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xl {
    margin: 80px !important; }
  .sm-up-m-xl-child > * {
    margin: 80px !important; }
  .sm-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xl {
    margin-top: 80px !important; }
  .sm-up-mt-xl-child > * {
    margin-top: 80px !important; }
  .sm-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xl {
    margin-bottom: 80px !important; }
  .sm-up-mb-xl-child > * {
    margin-bottom: 80px !important; }
  .sm-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .sm-up-my-xl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .sm-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xl {
    margin-left: 80px !important; }
  .sm-up-ml-xl-child > * {
    margin-left: 80px !important; }
  .sm-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xl {
    margin-right: 80px !important; }
  .sm-up-mr-xl-child > * {
    margin-right: 80px !important; }
  .sm-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .sm-up-mx-xl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .sm-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xl {
    padding: 80px !important; }
  .sm-up-p-xl-child > * {
    padding: 80px !important; }
  .sm-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xl {
    padding-top: 80px !important; }
  .sm-up-pt-xl-child > * {
    padding-top: 80px !important; }
  .sm-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xl {
    padding-bottom: 80px !important; }
  .sm-up-pb-xl-child > * {
    padding-bottom: 80px !important; }
  .sm-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .sm-up-py-xl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .sm-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xl {
    padding-left: 80px !important; }
  .sm-up-pl-xl-child > * {
    padding-left: 80px !important; }
  .sm-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xl {
    padding-right: 80px !important; }
  .sm-up-pr-xl-child > * {
    padding-right: 80px !important; }
  .sm-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .sm-up-px-xl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .sm-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xxl {
    margin: 120px !important; }
  .sm-up-m-xxl-child > * {
    margin: 120px !important; }
  .sm-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xxl {
    margin-top: 120px !important; }
  .sm-up-mt-xxl-child > * {
    margin-top: 120px !important; }
  .sm-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xxl {
    margin-bottom: 120px !important; }
  .sm-up-mb-xxl-child > * {
    margin-bottom: 120px !important; }
  .sm-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xxl {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .sm-up-my-xxl-child > * {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .sm-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important;
    margin-bottom: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xxl {
    margin-left: 120px !important; }
  .sm-up-ml-xxl-child > * {
    margin-left: 120px !important; }
  .sm-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xxl {
    margin-right: 120px !important; }
  .sm-up-mr-xxl-child > * {
    margin-right: 120px !important; }
  .sm-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xxl {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .sm-up-mx-xxl-child > * {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .sm-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important;
    margin-right: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xxl {
    padding: 120px !important; }
  .sm-up-p-xxl-child > * {
    padding: 120px !important; }
  .sm-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xxl {
    padding-top: 120px !important; }
  .sm-up-pt-xxl-child > * {
    padding-top: 120px !important; }
  .sm-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xxl {
    padding-bottom: 120px !important; }
  .sm-up-pb-xxl-child > * {
    padding-bottom: 120px !important; }
  .sm-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xxl {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .sm-up-py-xxl-child > * {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .sm-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important;
    padding-bottom: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xxl {
    padding-left: 120px !important; }
  .sm-up-pl-xxl-child > * {
    padding-left: 120px !important; }
  .sm-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xxl {
    padding-right: 120px !important; }
  .sm-up-pr-xxl-child > * {
    padding-right: 120px !important; }
  .sm-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 120px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xxl {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .sm-up-px-xxl-child > * {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .sm-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media (min-width: 768px) {
  .md-up-m-0 {
    margin: 0 !important; }
  .md-up-m-0-child > * {
    margin: 0 !important; }
  .md-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mt-0 {
    margin-top: 0 !important; }
  .md-up-mt-0-child > * {
    margin-top: 0 !important; }
  .md-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mb-0 {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-ml-0 {
    margin-left: 0 !important; }
  .md-up-ml-0-child > * {
    margin-left: 0 !important; }
  .md-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mr-0 {
    margin-right: 0 !important; }
  .md-up-mr-0-child > * {
    margin-right: 0 !important; }
  .md-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-p-0 {
    padding: 0 !important; }
  .md-up-p-0-child > * {
    padding: 0 !important; }
  .md-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pt-0 {
    padding-top: 0 !important; }
  .md-up-pt-0-child > * {
    padding-top: 0 !important; }
  .md-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pb-0 {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pl-0 {
    padding-left: 0 !important; }
  .md-up-pl-0-child > * {
    padding-left: 0 !important; }
  .md-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pr-0 {
    padding-right: 0 !important; }
  .md-up-pr-0-child > * {
    padding-right: 0 !important; }
  .md-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-m-xs {
    margin: 5px !important; }
  .md-up-m-xs-child > * {
    margin: 5px !important; }
  .md-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xs {
    margin-top: 5px !important; }
  .md-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xs {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xs {
    margin-left: 5px !important; }
  .md-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xs {
    margin-right: 5px !important; }
  .md-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-p-xs {
    padding: 5px !important; }
  .md-up-p-xs-child > * {
    padding: 5px !important; }
  .md-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xs {
    padding-top: 5px !important; }
  .md-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xs {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xs {
    padding-left: 5px !important; }
  .md-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xs {
    padding-right: 5px !important; }
  .md-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-m-sm {
    margin: 15px !important; }
  .md-up-m-sm-child > * {
    margin: 15px !important; }
  .md-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mt-sm {
    margin-top: 15px !important; }
  .md-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mb-sm {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-ml-sm {
    margin-left: 15px !important; }
  .md-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mr-sm {
    margin-right: 15px !important; }
  .md-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-p-sm {
    padding: 15px !important; }
  .md-up-p-sm-child > * {
    padding: 15px !important; }
  .md-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pt-sm {
    padding-top: 15px !important; }
  .md-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pb-sm {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pl-sm {
    padding-left: 15px !important; }
  .md-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pr-sm {
    padding-right: 15px !important; }
  .md-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-m-md {
    margin: 30px !important; }
  .md-up-m-md-child > * {
    margin: 30px !important; }
  .md-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mt-md {
    margin-top: 30px !important; }
  .md-up-mt-md-child > * {
    margin-top: 30px !important; }
  .md-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mb-md {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-ml-md {
    margin-left: 30px !important; }
  .md-up-ml-md-child > * {
    margin-left: 30px !important; }
  .md-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mr-md {
    margin-right: 30px !important; }
  .md-up-mr-md-child > * {
    margin-right: 30px !important; }
  .md-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-p-md {
    padding: 30px !important; }
  .md-up-p-md-child > * {
    padding: 30px !important; }
  .md-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pt-md {
    padding-top: 30px !important; }
  .md-up-pt-md-child > * {
    padding-top: 30px !important; }
  .md-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pb-md {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pl-md {
    padding-left: 30px !important; }
  .md-up-pl-md-child > * {
    padding-left: 30px !important; }
  .md-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pr-md {
    padding-right: 30px !important; }
  .md-up-pr-md-child > * {
    padding-right: 30px !important; }
  .md-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-m-lg {
    margin: 50px !important; }
  .md-up-m-lg-child > * {
    margin: 50px !important; }
  .md-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mt-lg {
    margin-top: 50px !important; }
  .md-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mb-lg {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-ml-lg {
    margin-left: 50px !important; }
  .md-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mr-lg {
    margin-right: 50px !important; }
  .md-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-p-lg {
    padding: 50px !important; }
  .md-up-p-lg-child > * {
    padding: 50px !important; }
  .md-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pt-lg {
    padding-top: 50px !important; }
  .md-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pb-lg {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pl-lg {
    padding-left: 50px !important; }
  .md-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pr-lg {
    padding-right: 50px !important; }
  .md-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-m-xl {
    margin: 80px !important; }
  .md-up-m-xl-child > * {
    margin: 80px !important; }
  .md-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xl {
    margin-top: 80px !important; }
  .md-up-mt-xl-child > * {
    margin-top: 80px !important; }
  .md-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xl {
    margin-bottom: 80px !important; }
  .md-up-mb-xl-child > * {
    margin-bottom: 80px !important; }
  .md-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (min-width: 768px) {
  .md-up-my-xl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .md-up-my-xl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .md-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xl {
    margin-left: 80px !important; }
  .md-up-ml-xl-child > * {
    margin-left: 80px !important; }
  .md-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xl {
    margin-right: 80px !important; }
  .md-up-mr-xl-child > * {
    margin-right: 80px !important; }
  .md-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .md-up-mx-xl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .md-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 768px) {
  .md-up-p-xl {
    padding: 80px !important; }
  .md-up-p-xl-child > * {
    padding: 80px !important; }
  .md-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xl {
    padding-top: 80px !important; }
  .md-up-pt-xl-child > * {
    padding-top: 80px !important; }
  .md-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xl {
    padding-bottom: 80px !important; }
  .md-up-pb-xl-child > * {
    padding-bottom: 80px !important; }
  .md-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (min-width: 768px) {
  .md-up-py-xl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .md-up-py-xl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .md-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xl {
    padding-left: 80px !important; }
  .md-up-pl-xl-child > * {
    padding-left: 80px !important; }
  .md-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xl {
    padding-right: 80px !important; }
  .md-up-pr-xl-child > * {
    padding-right: 80px !important; }
  .md-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (min-width: 768px) {
  .md-up-px-xl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .md-up-px-xl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .md-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 768px) {
  .md-up-m-xxl {
    margin: 120px !important; }
  .md-up-m-xxl-child > * {
    margin: 120px !important; }
  .md-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 120px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xxl {
    margin-top: 120px !important; }
  .md-up-mt-xxl-child > * {
    margin-top: 120px !important; }
  .md-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xxl {
    margin-bottom: 120px !important; }
  .md-up-mb-xxl-child > * {
    margin-bottom: 120px !important; }
  .md-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 120px !important; } }

@media (min-width: 768px) {
  .md-up-my-xxl {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .md-up-my-xxl-child > * {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .md-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important;
    margin-bottom: 120px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xxl {
    margin-left: 120px !important; }
  .md-up-ml-xxl-child > * {
    margin-left: 120px !important; }
  .md-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xxl {
    margin-right: 120px !important; }
  .md-up-mr-xxl-child > * {
    margin-right: 120px !important; }
  .md-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 120px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xxl {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .md-up-mx-xxl-child > * {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .md-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important;
    margin-right: 120px !important; } }

@media (min-width: 768px) {
  .md-up-p-xxl {
    padding: 120px !important; }
  .md-up-p-xxl-child > * {
    padding: 120px !important; }
  .md-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 120px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xxl {
    padding-top: 120px !important; }
  .md-up-pt-xxl-child > * {
    padding-top: 120px !important; }
  .md-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xxl {
    padding-bottom: 120px !important; }
  .md-up-pb-xxl-child > * {
    padding-bottom: 120px !important; }
  .md-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 120px !important; } }

@media (min-width: 768px) {
  .md-up-py-xxl {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .md-up-py-xxl-child > * {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .md-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important;
    padding-bottom: 120px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xxl {
    padding-left: 120px !important; }
  .md-up-pl-xxl-child > * {
    padding-left: 120px !important; }
  .md-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xxl {
    padding-right: 120px !important; }
  .md-up-pr-xxl-child > * {
    padding-right: 120px !important; }
  .md-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 120px !important; } }

@media (min-width: 768px) {
  .md-up-px-xxl {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .md-up-px-xxl-child > * {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .md-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-m-0 {
    margin: 0 !important; }
  .lg-up-m-0-child > * {
    margin: 0 !important; }
  .lg-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mt-0 {
    margin-top: 0 !important; }
  .lg-up-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mb-0 {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-ml-0 {
    margin-left: 0 !important; }
  .lg-up-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mr-0 {
    margin-right: 0 !important; }
  .lg-up-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-p-0 {
    padding: 0 !important; }
  .lg-up-p-0-child > * {
    padding: 0 !important; }
  .lg-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pt-0 {
    padding-top: 0 !important; }
  .lg-up-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pb-0 {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pl-0 {
    padding-left: 0 !important; }
  .lg-up-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pr-0 {
    padding-right: 0 !important; }
  .lg-up-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-m-xs {
    margin: 5px !important; }
  .lg-up-m-xs-child > * {
    margin: 5px !important; }
  .lg-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xs {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xs {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xs {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xs {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xs {
    padding: 5px !important; }
  .lg-up-p-xs-child > * {
    padding: 5px !important; }
  .lg-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xs {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xs {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xs {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xs {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-m-sm {
    margin: 15px !important; }
  .lg-up-m-sm-child > * {
    margin: 15px !important; }
  .lg-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-sm {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-sm {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-sm {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-sm {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-p-sm {
    padding: 15px !important; }
  .lg-up-p-sm-child > * {
    padding: 15px !important; }
  .lg-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-sm {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-sm {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-sm {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-sm {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-m-md {
    margin: 30px !important; }
  .lg-up-m-md-child > * {
    margin: 30px !important; }
  .lg-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-md {
    margin-top: 30px !important; }
  .lg-up-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-md {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-md {
    margin-left: 30px !important; }
  .lg-up-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-md {
    margin-right: 30px !important; }
  .lg-up-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-p-md {
    padding: 30px !important; }
  .lg-up-p-md-child > * {
    padding: 30px !important; }
  .lg-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-md {
    padding-top: 30px !important; }
  .lg-up-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-md {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-md {
    padding-left: 30px !important; }
  .lg-up-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-md {
    padding-right: 30px !important; }
  .lg-up-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-m-lg {
    margin: 50px !important; }
  .lg-up-m-lg-child > * {
    margin: 50px !important; }
  .lg-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-lg {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-lg {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-lg {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-lg {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-p-lg {
    padding: 50px !important; }
  .lg-up-p-lg-child > * {
    padding: 50px !important; }
  .lg-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-lg {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-lg {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-lg {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-lg {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xl {
    margin: 80px !important; }
  .lg-up-m-xl-child > * {
    margin: 80px !important; }
  .lg-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xl {
    margin-top: 80px !important; }
  .lg-up-mt-xl-child > * {
    margin-top: 80px !important; }
  .lg-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xl {
    margin-bottom: 80px !important; }
  .lg-up-mb-xl-child > * {
    margin-bottom: 80px !important; }
  .lg-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .lg-up-my-xl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .lg-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xl {
    margin-left: 80px !important; }
  .lg-up-ml-xl-child > * {
    margin-left: 80px !important; }
  .lg-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xl {
    margin-right: 80px !important; }
  .lg-up-mr-xl-child > * {
    margin-right: 80px !important; }
  .lg-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .lg-up-mx-xl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .lg-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xl {
    padding: 80px !important; }
  .lg-up-p-xl-child > * {
    padding: 80px !important; }
  .lg-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xl {
    padding-top: 80px !important; }
  .lg-up-pt-xl-child > * {
    padding-top: 80px !important; }
  .lg-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xl {
    padding-bottom: 80px !important; }
  .lg-up-pb-xl-child > * {
    padding-bottom: 80px !important; }
  .lg-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .lg-up-py-xl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .lg-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xl {
    padding-left: 80px !important; }
  .lg-up-pl-xl-child > * {
    padding-left: 80px !important; }
  .lg-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xl {
    padding-right: 80px !important; }
  .lg-up-pr-xl-child > * {
    padding-right: 80px !important; }
  .lg-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .lg-up-px-xl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .lg-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xxl {
    margin: 120px !important; }
  .lg-up-m-xxl-child > * {
    margin: 120px !important; }
  .lg-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xxl {
    margin-top: 120px !important; }
  .lg-up-mt-xxl-child > * {
    margin-top: 120px !important; }
  .lg-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xxl {
    margin-bottom: 120px !important; }
  .lg-up-mb-xxl-child > * {
    margin-bottom: 120px !important; }
  .lg-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xxl {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .lg-up-my-xxl-child > * {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .lg-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important;
    margin-bottom: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xxl {
    margin-left: 120px !important; }
  .lg-up-ml-xxl-child > * {
    margin-left: 120px !important; }
  .lg-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xxl {
    margin-right: 120px !important; }
  .lg-up-mr-xxl-child > * {
    margin-right: 120px !important; }
  .lg-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xxl {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .lg-up-mx-xxl-child > * {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .lg-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important;
    margin-right: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xxl {
    padding: 120px !important; }
  .lg-up-p-xxl-child > * {
    padding: 120px !important; }
  .lg-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xxl {
    padding-top: 120px !important; }
  .lg-up-pt-xxl-child > * {
    padding-top: 120px !important; }
  .lg-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xxl {
    padding-bottom: 120px !important; }
  .lg-up-pb-xxl-child > * {
    padding-bottom: 120px !important; }
  .lg-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xxl {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .lg-up-py-xxl-child > * {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .lg-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important;
    padding-bottom: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xxl {
    padding-left: 120px !important; }
  .lg-up-pl-xxl-child > * {
    padding-left: 120px !important; }
  .lg-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xxl {
    padding-right: 120px !important; }
  .lg-up-pr-xxl-child > * {
    padding-right: 120px !important; }
  .lg-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 120px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xxl {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .lg-up-px-xxl-child > * {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .lg-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-m-0 {
    margin: 0 !important; }
  .xl-up-m-0-child > * {
    margin: 0 !important; }
  .xl-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-mt-0 {
    margin-top: 0 !important; }
  .xl-up-mt-0-child > * {
    margin-top: 0 !important; }
  .xl-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-mb-0 {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-ml-0 {
    margin-left: 0 !important; }
  .xl-up-ml-0-child > * {
    margin-left: 0 !important; }
  .xl-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-mr-0 {
    margin-right: 0 !important; }
  .xl-up-mr-0-child > * {
    margin-right: 0 !important; }
  .xl-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-p-0 {
    padding: 0 !important; }
  .xl-up-p-0-child > * {
    padding: 0 !important; }
  .xl-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-pt-0 {
    padding-top: 0 !important; }
  .xl-up-pt-0-child > * {
    padding-top: 0 !important; }
  .xl-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-pb-0 {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-pl-0 {
    padding-left: 0 !important; }
  .xl-up-pl-0-child > * {
    padding-left: 0 !important; }
  .xl-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-pr-0 {
    padding-right: 0 !important; }
  .xl-up-pr-0-child > * {
    padding-right: 0 !important; }
  .xl-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1300px) {
  .xl-up-m-xs {
    margin: 5px !important; }
  .xl-up-m-xs-child > * {
    margin: 5px !important; }
  .xl-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-mt-xs {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-mb-xs {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-ml-xs {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-mr-xs {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-p-xs {
    padding: 5px !important; }
  .xl-up-p-xs-child > * {
    padding: 5px !important; }
  .xl-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-pt-xs {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-pb-xs {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-pl-xs {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-pr-xs {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1300px) {
  .xl-up-m-sm {
    margin: 15px !important; }
  .xl-up-m-sm-child > * {
    margin: 15px !important; }
  .xl-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-mt-sm {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-mb-sm {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-ml-sm {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-mr-sm {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-p-sm {
    padding: 15px !important; }
  .xl-up-p-sm-child > * {
    padding: 15px !important; }
  .xl-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-pt-sm {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-pb-sm {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-pl-sm {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-pr-sm {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 1300px) {
  .xl-up-m-md {
    margin: 30px !important; }
  .xl-up-m-md-child > * {
    margin: 30px !important; }
  .xl-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-mt-md {
    margin-top: 30px !important; }
  .xl-up-mt-md-child > * {
    margin-top: 30px !important; }
  .xl-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-mb-md {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-ml-md {
    margin-left: 30px !important; }
  .xl-up-ml-md-child > * {
    margin-left: 30px !important; }
  .xl-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-mr-md {
    margin-right: 30px !important; }
  .xl-up-mr-md-child > * {
    margin-right: 30px !important; }
  .xl-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-p-md {
    padding: 30px !important; }
  .xl-up-p-md-child > * {
    padding: 30px !important; }
  .xl-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-pt-md {
    padding-top: 30px !important; }
  .xl-up-pt-md-child > * {
    padding-top: 30px !important; }
  .xl-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-pb-md {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-pl-md {
    padding-left: 30px !important; }
  .xl-up-pl-md-child > * {
    padding-left: 30px !important; }
  .xl-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-pr-md {
    padding-right: 30px !important; }
  .xl-up-pr-md-child > * {
    padding-right: 30px !important; }
  .xl-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 1300px) {
  .xl-up-m-lg {
    margin: 50px !important; }
  .xl-up-m-lg-child > * {
    margin: 50px !important; }
  .xl-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-mt-lg {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-mb-lg {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-ml-lg {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-mr-lg {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-p-lg {
    padding: 50px !important; }
  .xl-up-p-lg-child > * {
    padding: 50px !important; }
  .xl-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-pt-lg {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-pb-lg {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-pl-lg {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-pr-lg {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 1300px) {
  .xl-up-m-xl {
    margin: 80px !important; }
  .xl-up-m-xl-child > * {
    margin: 80px !important; }
  .xl-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-mt-xl {
    margin-top: 80px !important; }
  .xl-up-mt-xl-child > * {
    margin-top: 80px !important; }
  .xl-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-mb-xl {
    margin-bottom: 80px !important; }
  .xl-up-mb-xl-child > * {
    margin-bottom: 80px !important; }
  .xl-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-my-xl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .xl-up-my-xl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .xl-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-ml-xl {
    margin-left: 80px !important; }
  .xl-up-ml-xl-child > * {
    margin-left: 80px !important; }
  .xl-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-mr-xl {
    margin-right: 80px !important; }
  .xl-up-mr-xl-child > * {
    margin-right: 80px !important; }
  .xl-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-mx-xl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .xl-up-mx-xl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .xl-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-p-xl {
    padding: 80px !important; }
  .xl-up-p-xl-child > * {
    padding: 80px !important; }
  .xl-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-pt-xl {
    padding-top: 80px !important; }
  .xl-up-pt-xl-child > * {
    padding-top: 80px !important; }
  .xl-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-pb-xl {
    padding-bottom: 80px !important; }
  .xl-up-pb-xl-child > * {
    padding-bottom: 80px !important; }
  .xl-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-py-xl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .xl-up-py-xl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .xl-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-pl-xl {
    padding-left: 80px !important; }
  .xl-up-pl-xl-child > * {
    padding-left: 80px !important; }
  .xl-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-pr-xl {
    padding-right: 80px !important; }
  .xl-up-pr-xl-child > * {
    padding-right: 80px !important; }
  .xl-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-px-xl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .xl-up-px-xl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .xl-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 1300px) {
  .xl-up-m-xxl {
    margin: 120px !important; }
  .xl-up-m-xxl-child > * {
    margin: 120px !important; }
  .xl-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-mt-xxl {
    margin-top: 120px !important; }
  .xl-up-mt-xxl-child > * {
    margin-top: 120px !important; }
  .xl-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-mb-xxl {
    margin-bottom: 120px !important; }
  .xl-up-mb-xxl-child > * {
    margin-bottom: 120px !important; }
  .xl-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-my-xxl {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .xl-up-my-xxl-child > * {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .xl-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 120px !important;
    margin-bottom: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-ml-xxl {
    margin-left: 120px !important; }
  .xl-up-ml-xxl-child > * {
    margin-left: 120px !important; }
  .xl-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-mr-xxl {
    margin-right: 120px !important; }
  .xl-up-mr-xxl-child > * {
    margin-right: 120px !important; }
  .xl-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-mx-xxl {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .xl-up-mx-xxl-child > * {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .xl-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 120px !important;
    margin-right: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-p-xxl {
    padding: 120px !important; }
  .xl-up-p-xxl-child > * {
    padding: 120px !important; }
  .xl-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-pt-xxl {
    padding-top: 120px !important; }
  .xl-up-pt-xxl-child > * {
    padding-top: 120px !important; }
  .xl-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-pb-xxl {
    padding-bottom: 120px !important; }
  .xl-up-pb-xxl-child > * {
    padding-bottom: 120px !important; }
  .xl-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-py-xxl {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .xl-up-py-xxl-child > * {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .xl-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 120px !important;
    padding-bottom: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-pl-xxl {
    padding-left: 120px !important; }
  .xl-up-pl-xxl-child > * {
    padding-left: 120px !important; }
  .xl-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-pr-xxl {
    padding-right: 120px !important; }
  .xl-up-pr-xxl-child > * {
    padding-right: 120px !important; }
  .xl-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 120px !important; } }

@media (min-width: 1300px) {
  .xl-up-px-xxl {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .xl-up-px-xxl-child > * {
    padding-left: 120px !important;
    padding-right: 120px !important; }
  .xl-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir, "Avenir Next", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease; }
  .plyr video,
  .plyr audio {
    border-radius: inherit;
    height: auto;
    vertical-align: middle;
    width: 100%; }
  .plyr button {
    font: inherit;
    line-height: inherit;
    width: auto; }
  .plyr:focus {
    outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }
  .plyr--full-ui *,
  .plyr--full-ui *::after,
  .plyr--full-ui *::before {
    box-sizing: inherit; }

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4a5764;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%; }
  .plyr__captions .plyr__caption {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    box-decoration-break: clone;
    line-height: 185%;
    padding: 0.2em 0.5em;
    white-space: pre-wrap; }
    .plyr__captions .plyr__caption div {
      display: inline; }
  .plyr__captions span:empty {
    display: none; }
  @media (min-width: 480px) {
    .plyr__captions {
      font-size: 16px;
      padding: 20px; } }
  @media (min-width: 768px) {
    .plyr__captions {
      font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px); }

.plyr__control {
  background: transparent;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  transition: all 0.3s ease; }
  .plyr__control svg {
    display: block;
    fill: currentColor;
    height: 18px;
    pointer-events: none;
    width: 18px; }
  .plyr__control:focus {
    outline: 0; }
  .plyr__control.plyr__tab-focus {
    box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
    outline: 0; }

a.plyr__control {
  text-decoration: none; }
  a.plyr__control::after, a.plyr__control::before {
    display: none; }

.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
.plyr__control.plyr__control--pressed .label--not-pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded='true'] {
  background: #00b3ff;
  color: #fff; }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded='true'] {
  background: #00b3ff;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(0, 179, 255, 0.8);
  border: 0;
  border-radius: 100%;
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; }
  .plyr__control--overlaid svg {
    left: 2px;
    position: relative; }
  .plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
    background: #00b3ff; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }
  .plyr__controls .plyr__progress__container {
    flex: 1;
    min-width: 0; }
  .plyr__controls .plyr__controls__item {
    margin-left: 2.5px; }
    .plyr__controls .plyr__controls__item:first-child {
      margin-left: 0;
      margin-right: auto; }
    .plyr__controls .plyr__controls__item.plyr__progress__container {
      padding-left: 2.5px; }
    .plyr__controls .plyr__controls__item.plyr__time {
      padding: 0 5px; }
    .plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time:first-child,
    .plyr__controls .plyr__controls__item.plyr__time + .plyr__time {
      padding-left: 0; }
    .plyr__controls .plyr__controls__item.plyr__volume {
      padding-right: 5px; }
    .plyr__controls .plyr__controls__item.plyr__volume:first-child {
      padding-right: 0; }
  .plyr__controls:empty {
    display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4a5764;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3; }
  @media (min-width: 480px) {
    .plyr--video .plyr__controls {
      padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%); }

.plyr [data-plyr='captions'],
.plyr [data-plyr='pip'],
.plyr [data-plyr='airplay'],
.plyr [data-plyr='fullscreen'] {
  display: none; }

.plyr--captions-enabled [data-plyr='captions'],
.plyr--pip-supported [data-plyr='pip'],
.plyr--airplay-supported [data-plyr='airplay'],
.plyr--fullscreen-enabled [data-plyr='fullscreen'] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }
  .plyr__menu .plyr__control svg {
    transition: transform 0.3s ease; }
  .plyr__menu .plyr__control[aria-expanded='true'] svg {
    transform: rotate(90deg); }
  .plyr__menu .plyr__control[aria-expanded='true'] .plyr__tooltip {
    display: none; }
  .plyr__menu__container {
    animation: plyr-popup 0.2s ease;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    bottom: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    color: #4a5764;
    font-size: 16px;
    margin-bottom: 10px;
    position: absolute;
    right: -3px;
    text-align: left;
    white-space: nowrap;
    z-index: 3; }
    .plyr__menu__container > div {
      overflow: hidden;
      transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }
    .plyr__menu__container::after {
      border: 4px solid transparent;
      border-top-color: rgba(255, 255, 255, 0.9);
      content: '';
      height: 0;
      position: absolute;
      right: 15px;
      top: 100%;
      width: 0; }
    .plyr__menu__container [role='menu'] {
      padding: 7px; }
    .plyr__menu__container [role='menuitem'],
    .plyr__menu__container [role='menuitemradio'] {
      margin-top: 2px; }
      .plyr__menu__container [role='menuitem']:first-child,
      .plyr__menu__container [role='menuitemradio']:first-child {
        margin-top: 0; }
    .plyr__menu__container .plyr__control {
      align-items: center;
      color: #4a5764;
      display: flex;
      font-size: 14px;
      padding: 4px 11px;
      user-select: none;
      width: 100%; }
      .plyr__menu__container .plyr__control > span {
        align-items: inherit;
        display: flex;
        width: 100%; }
      .plyr__menu__container .plyr__control::after {
        border: 4px solid transparent;
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .plyr__menu__container .plyr__control--forward {
        padding-right: 28px; }
        .plyr__menu__container .plyr__control--forward::after {
          border-left-color: rgba(74, 87, 100, 0.8);
          right: 5px; }
        .plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
          border-left-color: currentColor; }
      .plyr__menu__container .plyr__control--back {
        font-weight: 500;
        margin: 7px;
        margin-bottom: 3px;
        padding-left: 28px;
        position: relative;
        width: calc(100% - 14px); }
        .plyr__menu__container .plyr__control--back::after {
          border-right-color: rgba(74, 87, 100, 0.8);
          left: 7px; }
        .plyr__menu__container .plyr__control--back::before {
          background: #c1c9d1;
          box-shadow: 0 1px 0 #fff;
          content: '';
          height: 1px;
          left: 0;
          margin-top: 4px;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 100%; }
        .plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
          border-right-color: currentColor; }
    .plyr__menu__container .plyr__control[role='menuitemradio'] {
      padding-left: 7px; }
      .plyr__menu__container .plyr__control[role='menuitemradio']::before, .plyr__menu__container .plyr__control[role='menuitemradio']::after {
        border-radius: 100%; }
      .plyr__menu__container .plyr__control[role='menuitemradio']::before {
        background: rgba(0, 0, 0, 0.1);
        content: '';
        display: block;
        flex-shrink: 0;
        height: 16px;
        margin-right: 10px;
        transition: all 0.3s ease;
        width: 16px; }
      .plyr__menu__container .plyr__control[role='menuitemradio']::after {
        background: #fff;
        border: 0;
        height: 6px;
        left: 12px;
        opacity: 0;
        top: 50%;
        transform: translateY(-50%) scale(0);
        transition: transform 0.3s ease, opacity 0.3s ease;
        width: 6px; }
      .plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']::before {
        background: #00b3ff; }
      .plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']::after {
        opacity: 1;
        transform: translateY(-50%) scale(1); }
      .plyr__menu__container .plyr__control[role='menuitemradio'].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role='menuitemradio']:hover::before {
        background: rgba(0, 0, 0, 0.1); }
    .plyr__menu__container .plyr__menu__value {
      align-items: center;
      display: flex;
      margin-left: auto;
      margin-right: -5px;
      overflow: hidden;
      padding-left: 25px;
      pointer-events: none; }

.plyr--full-ui input[type='range'] {
  -webkit-appearance: none;
  /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: 26px;
  color: #00b3ff;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%; }
  .plyr--full-ui input[type='range']::-webkit-slider-runnable-track {
    background: transparent;
    border: 0;
    border-radius: 2.5px;
    height: 5px;
    transition: box-shadow 0.3s ease;
    user-select: none;
    background-image: linear-gradient(to right, currentColor var(--value, 0%), transparent var(--value, 0%)); }
  .plyr--full-ui input[type='range']::-webkit-slider-thumb {
    background: #fff;
    border: 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
    height: 13px;
    position: relative;
    transition: all 0.2s ease;
    width: 13px;
    -webkit-appearance: none;
    /* stylelint-disable-line */
    margin-top: -4px; }
  .plyr--full-ui input[type='range']::-moz-range-track {
    background: transparent;
    border: 0;
    border-radius: 2.5px;
    height: 5px;
    transition: box-shadow 0.3s ease;
    user-select: none; }
  .plyr--full-ui input[type='range']::-moz-range-thumb {
    background: #fff;
    border: 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
    height: 13px;
    position: relative;
    transition: all 0.2s ease;
    width: 13px; }
  .plyr--full-ui input[type='range']::-moz-range-progress {
    background: currentColor;
    border-radius: 2.5px;
    height: 5px; }
  .plyr--full-ui input[type='range']::-ms-track {
    background: transparent;
    border: 0;
    border-radius: 2.5px;
    height: 5px;
    transition: box-shadow 0.3s ease;
    user-select: none;
    color: transparent; }
  .plyr--full-ui input[type='range']::-ms-fill-upper {
    background: transparent;
    border: 0;
    border-radius: 2.5px;
    height: 5px;
    transition: box-shadow 0.3s ease;
    user-select: none; }
  .plyr--full-ui input[type='range']::-ms-fill-lower {
    background: transparent;
    border: 0;
    border-radius: 2.5px;
    height: 5px;
    transition: box-shadow 0.3s ease;
    user-select: none;
    background: currentColor; }
  .plyr--full-ui input[type='range']::-ms-thumb {
    background: #fff;
    border: 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
    height: 13px;
    position: relative;
    transition: all 0.2s ease;
    width: 13px;
    margin-top: 0; }
  .plyr--full-ui input[type='range']::-ms-tooltip {
    display: none; }
  .plyr--full-ui input[type='range']:focus {
    outline: 0; }
  .plyr--full-ui input[type='range']::-moz-focus-outer {
    border: 0; }
  .plyr--full-ui input[type='range'].plyr__tab-focus::-webkit-slider-runnable-track {
    box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
    outline: 0; }
  .plyr--full-ui input[type='range'].plyr__tab-focus::-moz-range-track {
    box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
    outline: 0; }
  .plyr--full-ui input[type='range'].plyr__tab-focus::-ms-track {
    box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
    outline: 0; }

.plyr--full-ui.plyr--video input[type='range']::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type='range']::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type='range']::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type='range']:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type='range']:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type='range']:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type='range']::-webkit-slider-runnable-track {
  background-color: rgba(193, 201, 209, 0.66); }

.plyr--full-ui.plyr--audio input[type='range']::-moz-range-track {
  background-color: rgba(193, 201, 209, 0.66); }

.plyr--full-ui.plyr--audio input[type='range']::-ms-track {
  background-color: rgba(193, 201, 209, 0.66); }

.plyr--full-ui.plyr--audio input[type='range']:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type='range']:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type='range']:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4a5764;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2; }
  .plyr__tooltip::before {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(255, 255, 255, 0.9);
    bottom: -4px;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 2; }

.plyr .plyr__control:hover .plyr__tooltip,
.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%; }
  .plyr__controls > .plyr__control:first-child .plyr__tooltip::before,
  .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
    left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%; }
  .plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
    left: auto;
    right: 16px;
    transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible,
.plyr__controls > .plyr__control:last-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:last-child .plyr__tooltip--visible {
  transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }
  .plyr--video.plyr--menu-open {
    overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }
  .plyr__progress input[type='range'], .plyr__progress__buffer {
    margin-left: -6.5px;
    margin-right: -6.5px;
    width: calc(100% + 13px); }
  .plyr__progress input[type='range'] {
    position: relative;
    z-index: 2; }
  .plyr__progress .plyr__tooltip {
    font-size: 14px;
    left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }
  .plyr__progress__buffer::-webkit-progress-bar {
    background: transparent; }
  .plyr__progress__buffer::-webkit-progress-value {
    background: currentColor;
    border-radius: 100px;
    min-width: 5px;
    transition: width 0.2s ease; }
  .plyr__progress__buffer::-moz-progress-bar {
    background: currentColor;
    border-radius: 100px;
    min-width: 5px;
    transition: width 0.2s ease; }
  .plyr__progress__buffer::-ms-fill {
    border-radius: 100px;
    transition: width 0.2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(193, 201, 209, 0.66); }

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(35, 41, 47, 0.6) 25%, transparent 25%, transparent 50%, rgba(35, 41, 47, 0.6) 50%, rgba(35, 41, 47, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(193, 201, 209, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative; }
  .plyr__volume input[type='range'] {
    margin-left: 5px;
    position: relative;
    z-index: 2; }
  @media (min-width: 480px) {
    .plyr__volume {
      max-width: 90px; } }
  @media (min-width: 768px) {
    .plyr__volume {
      max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr='mute'] {
  display: none !important; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }
  .plyr:fullscreen video {
    height: 100%; }
  .plyr:fullscreen .plyr__video-wrapper {
    height: 100%;
    position: static; }
  .plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .plyr:fullscreen .plyr__control .icon--exit-fullscreen {
    display: block; }
    .plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }
  .plyr:fullscreen.plyr--hide-controls {
    cursor: none; }
  @media (min-width: 1024px) {
    .plyr:fullscreen .plyr__captions {
      font-size: 21px; } }

/* stylelint-disable-next-line */
.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }
  .plyr:-webkit-full-screen video {
    height: 100%; }
  .plyr:-webkit-full-screen .plyr__video-wrapper {
    height: 100%;
    position: static; }
  .plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
    display: block; }
    .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }
  .plyr:-webkit-full-screen.plyr--hide-controls {
    cursor: none; }
  @media (min-width: 1024px) {
    .plyr:-webkit-full-screen .plyr__captions {
      font-size: 21px; } }

/* stylelint-disable-next-line */
.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }
  .plyr:-moz-full-screen video {
    height: 100%; }
  .plyr:-moz-full-screen .plyr__video-wrapper {
    height: 100%;
    position: static; }
  .plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
    display: block; }
    .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }
  .plyr:-moz-full-screen.plyr--hide-controls {
    cursor: none; }
  @media (min-width: 1024px) {
    .plyr:-moz-full-screen .plyr__captions {
      font-size: 21px; } }

/* stylelint-disable-next-line */
.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }
  .plyr:-ms-fullscreen video {
    height: 100%; }
  .plyr:-ms-fullscreen .plyr__video-wrapper {
    height: 100%;
    position: static; }
  .plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
    display: block; }
    .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }
  .plyr:-ms-fullscreen.plyr--hide-controls {
    cursor: none; }
  @media (min-width: 1024px) {
    .plyr:-ms-fullscreen .plyr__captions {
      font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }
  .plyr--fullscreen-fallback video {
    height: 100%; }
  .plyr--fullscreen-fallback .plyr__video-wrapper {
    height: 100%;
    position: static; }
  .plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
    display: block; }
    .plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
      display: none; }
  .plyr--fullscreen-fallback.plyr--hide-controls {
    cursor: none; }
  @media (min-width: 1024px) {
    .plyr--fullscreen-fallback .plyr__captions {
      font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }
  .plyr__ads > div,
  .plyr__ads > div iframe {
    height: 100%;
    position: absolute;
    width: 100%; }
  .plyr__ads::after {
    background: rgba(35, 41, 47, 0.8);
    border-radius: 2px;
    bottom: 10px;
    color: #fff;
    content: attr(data-badge-text);
    font-size: 11px;
    padding: 2px 6px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    z-index: 3; }
  .plyr__ads::after:empty {
    display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  z-index: 2; }
  .plyr__preview-thumb--is-shown {
    opacity: 1;
    transform: translate(0, 0) scale(1); }
  .plyr__preview-thumb::before {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(255, 255, 255, 0.9);
    bottom: -4px;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 2; }
  .plyr__preview-thumb__image-container {
    background: #c1c9d1;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    z-index: 0; }
    .plyr__preview-thumb__image-container img {
      height: 100%;
      left: 0;
      max-height: none;
      max-width: none;
      position: absolute;
      top: 0;
      width: 100%; }
  .plyr__preview-thumb__time-container {
    bottom: 6px;
    left: 0;
    position: absolute;
    right: 0;
    white-space: nowrap;
    z-index: 3; }
    .plyr__preview-thumb__time-container span {
      background-color: rgba(0, 0, 0, 0.55);
      border-radius: 2px;
      color: #fff;
      font-size: 14px;
      padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1; }
  .plyr__preview-scrubbing--is-shown {
    opacity: 1; }
  .plyr__preview-scrubbing img {
    height: 100%;
    left: 0;
    max-height: none;
    max-width: none;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%; }

.plyr--no-transition {
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/
@font-face {
  font-family: 'Gotham';
  src: url("../fonts/gotham/Gotham-Bold.eot");
  src: url("../fonts/gotham/Gotham-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/Gotham-Bold.woff2") format("woff2"), url("../fonts/gotham/Gotham-Bold.woff") format("woff"), url("../fonts/gotham/Gotham-Bold.ttf") format("truetype"), url("../fonts/gotham/Gotham-Bold.svg#Gotham-Bold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/gotham/Gotham-Medium.eot");
  src: url("../fonts/gotham/Gotham-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/Gotham-Medium.woff2") format("woff2"), url("../fonts/gotham/Gotham-Medium.woff") format("woff"), url("../fonts/gotham/Gotham-Medium.ttf") format("truetype"), url("../fonts/gotham/Gotham-Medium.svg#Gotham-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/gotham/Gotham-Light.eot");
  src: url("../fonts/gotham/Gotham-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/Gotham-Light.woff2") format("woff2"), url("../fonts/gotham/Gotham-Light.woff") format("woff"), url("../fonts/gotham/Gotham-Light.ttf") format("truetype"), url("../fonts/gotham/Gotham-Light.svg#Gotham-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Medium.eot");
  src: url("../fonts/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Medium.woff2") format("woff2"), url("../fonts/roboto/Roboto-Medium.woff") format("woff"), url("../fonts/roboto/Roboto-Medium.ttf") format("truetype"), url("../fonts/roboto/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: normal;
  font-style: normal; }

/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: "Roboto", sans-serif;
  color: rgba(146, 146, 146, 0.8);
  font-size: 16px;
  line-height: 1.875;
  font-weight: 300;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent; }

h1, h2, h3, h4, h5, h6 {
  color: #0674B7;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 1px; }

button:focus, input:focus, textarea:focus {
  outline: none !important;
  box-shadow: none !important; }

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none; }

img {
  max-width: inherit; }

a, img, span, button {
  display: inline-block; }

a {
  color: #0674B7; }
  a:hover {
    color: rgba(108, 108, 108, 0.8); }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
  transition: background-color 5000s ease-in-out 0s; }

input {
  -webkit-appearance: none;
  border-radius: 0;
  box-shadow: none; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  #page-home .banner {
    background-color: #0674B7; }
    #page-home .banner .text-container {
      position: relative; }
  #page-home .overlay {
    display: none; }
  #page-home .key-benefits .item-container .text {
    display: block; } }

/*
|--------------------
|      HEADER
|--------------------
*/
.c-default {
  cursor: default; }

header {
  position: fixed;
  top: 0; }
  @media (max-width: 1299.98px) {
    header {
      transform: none !important;
      top: 0 !important; } }
  header .btn-menu {
    color: #424242;
    transition: .3s color; }
    header .btn-menu:hover {
      color: #0674B7; }
  header .contact {
    display: block; }
    @media (max-width: 1299.98px) {
      header .contact {
        display: none; } }
  header .contact-responsive {
    display: none; }
    @media (max-width: 1299.98px) {
      header .contact-responsive {
        display: block; } }
  header .logo-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100px; }
    @media (max-width: 1299.98px) {
      header .logo-container {
        top: 50%;
        transform: translate(-50%, -50%);
        width: 64px; } }
    header .logo-container img {
      width: 100%; }
  header .current-menu-item {
    color: #0674B7;
    font-weight: 700;
    position: relative; }
    header .current-menu-item a {
      color: inherit; }
    header .current-menu-item:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 5px;
      background-color: rgba(146, 146, 146, 0.8);
      transform: skewX(20deg); }
  header nav.desktop {
    margin-top: 40px; }
    @media (max-width: 1299.98px) {
      header nav.desktop {
        display: none; } }
    header nav.desktop ul {
      margin-bottom: 0;
      display: flex;
      justify-content: center; }
      header nav.desktop ul li {
        position: relative;
        margin-left: 10px;
        padding: 5px 20px 5px 15px; }
        header nav.desktop ul li .nav-drop {
          display: none;
          position: absolute;
          width: 250px;
          left: 50%;
          transform: translateX(-50%);
          padding: 10px 20px; }
          header nav.desktop ul li .nav-drop li {
            display: inline-block;
            position: relative;
            z-index: 3; }
            header nav.desktop ul li .nav-drop li:after {
              display: none; }
        header nav.desktop ul li:hover .nav-drop {
          display: block; }
      header nav.desktop ul > li:not(:last-child):after {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        right: 0;
        top: 0;
        transform: rotate(-20deg);
        transform-origin: center;
        background-color: #424242; }
  header nav.responsive {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    transition: all 0.3s ease;
    z-index: 103; }
    header nav.responsive .arrow-menu {
      height: 12px;
      width: 12px;
      position: absolute;
      right: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }
    @media (max-width: 1299.98px) {
      header nav.responsive {
        display: flex; } }
    header nav.responsive.open {
      left: 0%;
      transition: all 0.3s ease; }
    header nav.responsive .logo-container-responsive {
      position: relative;
      left: 0;
      transform: none; }
    header nav.responsive > ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      header nav.responsive > ul .menu-item-has-children {
        text-align: center; }
      header nav.responsive > ul .nav-drop {
        display: block;
        overflow: hidden;
        list-style-type: none;
        text-align: center; }
        header nav.responsive > ul .nav-drop a {
          font-size: 14px; }
      header nav.responsive > ul a, header nav.responsive > ul .contact-responsive {
        color: #0674B7; }
        @media (max-width: 767.98px) {
          header nav.responsive > ul a, header nav.responsive > ul .contact-responsive {
            font-size: 16px; } }
    header nav.responsive .logo-container-responsive {
      display: block;
      position: absolute;
      bottom: 50px;
      top: auto;
      left: auto;
      margin: auto; }

/*
|--------------------
|      FOOTER
|--------------------
*/
.newsletter-form a {
  display: none; }

/*
|--------------------
|      CONTENT
|--------------------
*/
.page-offset {
  padding-top: 162px; }
  @media (max-width: 1299.98px) {
    .page-offset {
      padding-top: 102px; } }

h1 {
  font-weight: 700 !important; }

/*
|
| Helpers
|-----------
*/
.mx-auto {
  margin: auto; }

/*
|
| Banner
|---------
*/
.banner {
  position: relative;
  width: 100%;
  height: auto !important;
  padding: 150px 0; }
  .banner:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 100%;
    background: #2574B7;
    mix-blend-mode: multiply;
    top: 0; }
    @media (max-width: 991.98px) {
      .banner:before {
        width: 100%; } }
  .banner .item-content {
    width: 100%; }

.single .banner {
  height: 480px; }

/*
|
| Custom card
|--------------
*/
.custom-card .item-img {
  width: 100%;
  height: 200px; }

.custom-card .item-content {
  padding: 15px 30px;
  border: 1px solid #D9D9D9; }

/*
|
| Slider Links
|--------------
*/
.slider-links {
  background: #0674B7; }
  .slider-links .swiper-container {
    padding-right: 250px; }
    @media (max-width: 991.98px) {
      .slider-links .swiper-container {
        padding-right: 0; } }
    .slider-links .swiper-container .arrow-next, .slider-links .swiper-container .arrow-prev {
      position: absolute;
      height: 30px;
      width: 30px;
      bottom: 20%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
      cursor: pointer; }
      .slider-links .swiper-container .arrow-next.button-disabled, .slider-links .swiper-container .arrow-prev.button-disabled {
        opacity: 0; }
      @media (max-width: 991.98px) {
        .slider-links .swiper-container .arrow-next, .slider-links .swiper-container .arrow-prev {
          display: none; } }
    .slider-links .swiper-container .arrow-next {
      right: 250px; }
    .slider-links .swiper-container .arrow-prev {
      left: 50px;
      transform: rotate(-180deg); }
  .slider-links .swiper-slide {
    position: relative;
    height: auto !important;
    display: flex;
    align-items: center; }
    .slider-links .swiper-slide .overlay {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      top: 0;
      left: -210px;
      background: #0674B7; }
      @media (max-width: 991.98px) {
        .slider-links .swiper-slide .overlay {
          display: none; } }
    .slider-links .swiper-slide:before {
      content: "";
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #2574B7;
      mix-blend-mode: multiply; }
    .slider-links .swiper-slide .swiper-slide-inner {
      position: relative;
      z-index: 5;
      padding-top: 200px;
      padding-bottom: 200px; }
      @media (min-width: 992px) {
        .slider-links .swiper-slide .swiper-slide-inner {
          max-width: 100%; } }
  @media (min-width: 992px) and (min-width: 576px) {
    .slider-links .swiper-slide .swiper-slide-inner {
      padding-left: calc((100vw - 540px) / 2); } }
  @media (min-width: 992px) and (min-width: 768px) {
    .slider-links .swiper-slide .swiper-slide-inner {
      padding-left: calc((100vw - 720px) / 2); } }
  @media (min-width: 992px) and (min-width: 992px) {
    .slider-links .swiper-slide .swiper-slide-inner {
      padding-left: calc((100vw - 960px) / 2); } }
  @media (min-width: 992px) and (min-width: 1300px) {
    .slider-links .swiper-slide .swiper-slide-inner {
      padding-left: calc((100vw - 1240px) / 2); } }
      @media (max-width: 991.98px) {
        .slider-links .swiper-slide .swiper-slide-inner {
          padding: 50px 15px; } }

.cms {
  /*
    |
    | Lists
    |--------------
    */ }
  .cms ul {
    list-style: none; }
    .cms ul li {
      display: flex; }
      .cms ul li:before {
        content: "•";
        margin-right: 5px;
        border-radius: 50%; }

/*
|
| Loader
|----------
*/
.loader {
  display: none;
  margin: 10px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid #d1e5f3;
  border-right: 2px solid #d1e5f3;
  border-bottom: 2px solid #d1e5f3;
  border-left: 2px solid #177FC3;
  -webkit-animation: load8 .5s infinite linear;
  animation: load8 .5s infinite linear; }

.loader,
.loader:after {
  border-radius: 50%;
  width: 30px;
  height: 30px; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 200;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0; }
  .cookie-banner .item-close {
    font-size: 24px;
    cursor: pointer; }
  .cookie-banner .btn, .cookie-banner .btn-black, .cookie-banner .btn-white, .cookie-banner .btn-xs, .cookie-banner .btn-sm, .cookie-banner .btn-md, .cookie-banner .btn-lg, .cookie-banner .newsletter-form .gform_footer input, .newsletter-form .gform_footer .cookie-banner input {
    border: 1px solid transparent;
    background-color: #0674B7;
    color: #FFFFFF; }
    .cookie-banner .btn:hover, .cookie-banner .btn-black:hover, .cookie-banner .btn-white:hover, .cookie-banner .btn-xs:hover, .cookie-banner .btn-sm:hover, .cookie-banner .btn-md:hover, .cookie-banner .btn-lg:hover, .cookie-banner .newsletter-form .gform_footer input:hover, .newsletter-form .gform_footer .cookie-banner input:hover {
      border: 1px solid #0674B7;
      background-color: #FFFFFF;
      color: #0674B7; }
  @media (max-width: 767.98px) {
    .cookie-banner {
      padding: 20px 0;
      font-size: 12px; } }
  @media (max-width: 575.98px) {
    .cookie-banner {
      padding: 10px 0 20px 0; }
      .cookie-banner .item-close {
        font-size: 18px; } }

/*
|
| Width & Height 100%
|----------------------
*/
.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

/*
|
| Text alignment
|----------------
*/
.text-center {
  text-align: center; }

.text ul {
  margin-left: 20px; }

.strong {
  font-weight: bold; }

/*
|
| Links
|--------
*/
a {
  text-decoration: none; }

/*
|--------------------
|     PAGE HOME
|--------------------
*/
#page-home .banner {
  padding: 0 !important; }

@media (max-width: 991.98px) {
  #page-home .home-footer {
    display: none; } }

/*
|
| Banner
|---------
*/
#fp-nav {
  right: 50px !important;
  top: 60% !important;
  z-index: 3; }
  @media (max-width: 991.98px) {
    #fp-nav {
      display: none; } }
  #fp-nav ul {
    position: relative; }
    #fp-nav ul li:not(:last-child) {
      margin-bottom: 28px; }
    #fp-nav ul li {
      position: relative;
      height: 10px;
      width: 10px; }
      #fp-nav ul li:last-child:before {
        display: none; }
      #fp-nav ul li:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 42px;
        background-color: #0674B7; }
    #fp-nav ul li a {
      position: relative;
      border: 2px solid #0674B7;
      border-radius: 50%;
      background: transparent;
      transition: 1s all; }
      #fp-nav ul li a:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 2px solid #0674B7;
        transition: 1s all; }
      #fp-nav ul li a span {
        display: none; }
      #fp-nav ul li a.active {
        background-color: #0674B7; }
        #fp-nav ul li a.active:after {
          transform: translate(-50%, -50%) scale(1); }
  #fp-nav.white ul li:before {
    background-color: #FFFFFF; }
  #fp-nav.white ul li:after {
    background-color: #FFFFFF; }
  #fp-nav.white ul li a {
    border: 2px solid #FFFFFF; }
    #fp-nav.white ul li a:after {
      border: 2px solid #FFFFFF; }

.banner {
  height: 820px; }
  @media (max-width: 767.98px) {
    .banner {
      height: auto; } }
  .banner .overlay {
    position: relative; }
    .banner .overlay::before, .banner .overlay::after {
      content: "";
      height: 100%;
      position: absolute;
      top: 0; }
    .banner .overlay::before {
      z-index: 1;
      width: 20%;
      right: 0;
      background-color: #D9D9D9; }
      @media (max-width: 767.98px) {
        .banner .overlay::before {
          width: 0%; } }
    .banner .overlay::after {
      z-index: 3;
      width: 80%;
      left: 0;
      background-color: #0674B7;
      mix-blend-mode: multiply; }
      @media (max-width: 767.98px) {
        .banner .overlay::after {
          width: 100%; } }
    @media (max-width: 767.98px) {
      .banner .overlay .container, .banner .overlay .slider-links .swiper-slide .swiper-slide-inner, .slider-links .swiper-slide .banner .overlay .swiper-slide-inner {
        max-width: 100%; } }
    .banner .overlay .container .banner-bg, .banner .overlay .slider-links .swiper-slide .swiper-slide-inner .banner-bg, .slider-links .swiper-slide .banner .overlay .swiper-slide-inner .banner-bg {
      position: relative;
      z-index: 2;
      background-size: inherit;
      background-position: center;
      background-repeat: no-repeat; }
      @media (max-width: 767.98px) {
        .banner .overlay .container .banner-bg, .banner .overlay .slider-links .swiper-slide .swiper-slide-inner .banner-bg, .slider-links .swiper-slide .banner .overlay .swiper-slide-inner .banner-bg {
          background-size: cover; } }
  .banner .text-container {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 767.98px) {
      .banner .text-container {
        text-align: center; } }
    @media (max-width: 767.98px) {
      .banner .text-container .logo {
        width: 80%; } }
    .banner .text-container h1 {
      font-size: 46px !important; }
      @media (max-width: 767.98px) {
        .banner .text-container h1 {
          font-size: 32px !important; } }

/*
|
| Section stand for
|--------------------
*/
.stand-for {
  background-size: auto 120%;
  background-position: left center;
  background-repeat: no-repeat; }
  @media (max-width: 767.98px) {
    .stand-for {
      background-image: none; } }

/*
|
| Section video
|----------------
*/
.video video {
  width: 100%;
  height: 740px; }
  @media (max-width: 991.98px) {
    .video video {
      height: 480px; } }

.video .plyr__poster {
  background-size: cover; }

.video .plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 0 rgba(26, 175, 255, 0); }

.video .plyr__control.plyr__control--overlaid {
  width: 150px;
  height: 150px;
  border: 1px solid #FFFFFF;
  background-color: transparent; }
  .video .plyr__control.plyr__control--overlaid svg {
    left: auto;
    margin: 0 auto; }

.video .plyr--stopped.plyr--video .plyr__controls {
  opacity: 0;
  pointer-events: none; }

.video .plyr--stopped.plyr--video.plyr--init-play .plyr__controls {
  opacity: 1;
  pointer-events: inherit; }

/*
|
| Section vision
|-----------------
*/
.vision .item {
  padding: 20px;
  background-color: transparent;
  transition: all 0.3s ease; }
  @media (max-width: 767.98px) {
    .vision .item {
      padding: 20px; } }
  .vision .item-img-container {
    width: 110px;
    height: 110px;
    margin: 0 auto;
    position: relative;
    background-color: #FFFFFF;
    border-radius: 100%;
    transition: all 0.3s ease; }
    .vision .item-img-container > div {
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.3s ease; }
    .vision .item-img-container .not-hover {
      opacity: 1; }
    .vision .item-img-container .hover {
      opacity: 0; }
  .vision .item-title {
    line-height: 1.6; }
  .vision .item-text {
    padding: 0 20px; }
  .vision .item-title, .vision .item-text {
    transition: all 0.3s ease; }
  .vision .item:hover {
    background-color: #FFFFFF; }
    .vision .item:hover .item-img-container {
      background-color: #0674B7; }
      .vision .item:hover .item-img-container .not-hover {
        opacity: 0; }
      .vision .item:hover .item-img-container .hover {
        opacity: 1; }
    .vision .item:hover .item-title,
    .vision .item:hover .item-text {
      color: #0674B7; }
    .vision .item:hover svg {
      fill: #FFFFFF; }

/*
|
| Section slider
|-----------------
*/
.slider .full-swiper {
  padding: 0 10% 0 0; }
  .slider .full-swiper .swiper-slide {
    overflow: hidden; }

.slider .txt-swiper .swiper-slide {
  display: none; }

@media (max-width: 991.98px) {
  .slider .txt-swiper .fs-lg {
    font-size: 18px !important; } }

.slider .swiper-pagination {
  position: relative; }

.slider .item-img {
  height: 700px; }
  @media (max-width: 991.98px) {
    .slider .item-img {
      height: 340px; } }

/*
|
| Section key numbers
|----------------------
*/
.key-numbers .item {
  border-right: 1px solid #D9D9D9; }
  .key-numbers .item:last-child {
    border-right: 0; }
  @media (max-width: 991.98px) {
    .key-numbers .item:nth-child(even) {
      border-right: 0; } }
  @media (max-width: 767.98px) {
    .key-numbers .item {
      border-right: 0; } }

/*
|
| Section key benefits
|-----------------------
*/
.key-benefits .item-container {
  border-top: 1px solid #D9D9D9;
  cursor: pointer; }
  .key-benefits .item-container .item-title {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .key-benefits .item-container .item-title svg {
      color: #000000; }
  .key-benefits .item-container .item-text {
    display: none; }

/*
|
| Section references
|---------------------
*/
.references .swiper-wrapper {
  padding: 0 5%; }
  .references .swiper-wrapper .swiper-slide {
    overflow: hidden; }

.references .logo {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer; }
  @media (max-width: 767.98px) {
    .references .logo {
      max-height: 90px; } }
  @media (max-width: 575.98px) {
    .references .logo {
      max-height: 60px; } }

.page-template-cms header {
  position: absolute !important; }

.page-template-cms .banner-cms {
  height: 100vh;
  width: 100%;
  background-color: #0674B7;
  display: flex;
  justify-content: center;
  align-items: center; }
  .page-template-cms .banner-cms .scroll-down {
    position: absolute;
    bottom: 80px;
    width: 100%; }
    @media (max-width: 991.98px) {
      .page-template-cms .banner-cms .scroll-down {
        bottom: 50px; } }
    @media (max-width: 767.98px) {
      .page-template-cms .banner-cms .scroll-down {
        bottom: 40px; } }
    @media (max-width: 575.98px) {
      .page-template-cms .banner-cms .scroll-down {
        bottom: 30px; } }

.page-template-cms .content h1, .page-template-cms .content h2, .page-template-cms .content h3, .page-template-cms .content h4, .page-template-cms .content h5, .page-template-cms .content h6 {
  color: #000000; }

.page-template-cms .content h3 {
  margin-bottom: 30px; }

.page-template-cms .content ul {
  margin-left: 40px; }

/*
|--------------------
|       Contact
|--------------------
*/
#page-contact {
  /*
    |
    | Section contact
    |------------------ 
    */ }
  @media (min-width: 576px) {
    #page-contact .head .left-side {
      padding-left: calc((100vw - 540px) / 2); } }
  @media (min-width: 768px) {
    #page-contact .head .left-side {
      padding-left: calc((100vw - 720px) / 2); } }
  @media (min-width: 992px) {
    #page-contact .head .left-side {
      padding-left: calc((100vw - 960px) / 2); } }
  @media (min-width: 1300px) {
    #page-contact .head .left-side {
      padding-left: calc((100vw - 1240px) / 2); } }
  @media (max-width: 991.98px) {
    #page-contact .head .left-side .btn-container {
      flex-direction: column;
      align-items: center; }
      #page-contact .head .left-side .btn-container a {
        margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    #page-contact .adresses-container {
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      #page-contact .adresses-container .adress-item:not(last-child) {
        margin-bottom: 40px; } }

#page-solutions .filter .filter-container {
  display: flex;
  justify-content: center; }
  @media (max-width: 991.98px) {
    #page-solutions .filter .filter-container {
      flex-direction: column-reverse; } }
  #page-solutions .filter .filter-container .filter-item {
    cursor: pointer; }
    @media (max-width: 991.98px) {
      #page-solutions .filter .filter-container .filter-item {
        text-align: center; } }
    #page-solutions .filter .filter-container .filter-item.active {
      color: #0674B7; }
    #page-solutions .filter .filter-container .filter-item:hover {
      color: #0674B7; }

@media (max-width: 991.98px) {
  #page-solutions .products .product-item .product-image {
    height: 250px; } }

#page-solutions .swiper-pagination {
  position: absolute;
  right: -10%;
  top: 50%;
  transform: translateY(-50%); }
  @media (max-width: 991.98px) {
    #page-solutions .swiper-pagination {
      position: relative;
      display: flex;
      justify-content: center;
      transform: none;
      right: auto;
      margin-top: 50px; } }
  #page-solutions .swiper-pagination:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 100%;
    background-color: #0674B7; }
    @media (max-width: 991.98px) {
      #page-solutions .swiper-pagination:after {
        height: 1px;
        width: 100%;
        left: 0;
        transform: translateY(-50%); } }
  #page-solutions .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 42px; }
    @media (max-width: 991.98px) {
      #page-solutions .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
        margin-right: 42px;
        margin-bottom: 0; } }
  #page-solutions .swiper-pagination .swiper-pagination-bullet {
    position: relative;
    display: block;
    width: 14px;
    height: 14px;
    border: 2px solid #0674B7;
    border-radius: 50%;
    background-color: #FFF !important;
    transition: 1s all;
    opacity: 1; }
    #page-solutions .swiper-pagination .swiper-pagination-bullet:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border: 2px solid #0674B7;
      transition: 1s all; }
    #page-solutions .swiper-pagination .swiper-pagination-bullet-active {
      background-color: #0674B7 !important; }
      #page-solutions .swiper-pagination .swiper-pagination-bullet-active:after {
        transform: translate(-50%, -50%) scale(1); }

#page-solutions .video #player {
  width: 100%;
  height: 740px; }
  @media (max-width: 991.98px) {
    #page-solutions .video #player {
      height: 480px; } }

#page-solutions .video .plyr__poster {
  background-size: cover; }

#page-solutions .video .plyr__video-embed {
  padding-bottom: 0;
  height: 100%; }

#page-solutions .video .plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 0 rgba(26, 175, 255, 0); }

#page-solutions .video .plyr__control.plyr__control--overlaid {
  width: 150px;
  height: 150px;
  border: 1px solid #FFFFFF;
  background-color: transparent; }
  #page-solutions .video .plyr__control.plyr__control--overlaid svg {
    left: auto;
    margin: 0 auto; }

#page-solutions .video .plyr--stopped.plyr--video .plyr__controls {
  opacity: 0;
  pointer-events: none; }

#page-solutions .video .plyr--stopped.plyr--video.plyr--init-play .plyr__controls {
  opacity: 1;
  pointer-events: inherit; }

#page-solutions .help .help-item {
  border-bottom: 1px solid #0674B7;
  padding-left: 0;
  padding-right: 0; }
  #page-solutions .help .help-item a {
    width: 100%; }
  #page-solutions .help .help-item:first-child {
    border-right: 1px solid #0674B7; }
    @media (max-width: 991.98px) {
      #page-solutions .help .help-item:first-child {
        border-right: none; } }
  #page-solutions .help .help-item .help-download, #page-solutions .help .help-item .help-guide {
    cursor: pointer;
    transition: .3s all; }
    #page-solutions .help .help-item .help-download h3, #page-solutions .help .help-item .help-guide h3 {
      transition: .3s all; }
    #page-solutions .help .help-item .help-download:after, #page-solutions .help .help-item .help-guide:after {
      content: "";
      position: relative;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 32px;
      width: 32px;
      margin-left: 20px;
      transition: .3s all; }
    #page-solutions .help .help-item .help-download:hover, #page-solutions .help .help-item .help-guide:hover {
      background-color: #0674B7; }
      #page-solutions .help .help-item .help-download:hover h3, #page-solutions .help .help-item .help-guide:hover h3 {
        color: #FFFFFF; }
  #page-solutions .help .help-item .help-download:after {
    background-image: url("../../src/img/icon/download.svg"); }
  #page-solutions .help .help-item .help-download:hover:after {
    background-image: url("../../src/img/icon/download-white.svg"); }
  #page-solutions .help .help-item .help-guide:after {
    background-image: url("../../src/img/icon/link.svg"); }
  #page-solutions .help .help-item .help-guide:hover:after {
    background-image: url("../../src/img/icon/link-white.svg"); }

#page-customer-single .client-logo {
  width: 150px; }
  #page-customer-single .client-logo img {
    width: 100%; }
  @media (max-width: 991.98px) {
    #page-customer-single .client-logo {
      width: 100px; } }

#page-customer-single .swiper-wrapper {
  height: auto; }

#page-customer-single .arrow-pagination {
  display: flex;
  justify-content: center; }
  #page-customer-single .arrow-pagination .slider-btn {
    height: 24px;
    width: 24px;
    position: relative;
    cursor: pointer; }
    #page-customer-single .arrow-pagination .slider-btn:focus {
      outline: none; }
    #page-customer-single .arrow-pagination .slider-btn:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../img/icon/right-arrow-blue.svg");
      background-size: contain;
      background-position: center; }
    #page-customer-single .arrow-pagination .slider-btn.slider-button-prev {
      transform: rotate(-180deg); }

#page-customers h1 {
  font-size: 48px; }

#page-customers .all-customers .customer-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 371px;
  margin-bottom: 30px; }
  @media (max-width: 991.98px) {
    #page-customers .all-customers .customer-card {
      height: 220px; } }
  #page-customers .all-customers .customer-card .logo {
    height: 150px;
    width: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
    @media (max-width: 991.98px) {
      #page-customers .all-customers .customer-card .logo {
        height: 60px;
        width: 90px; } }
  #page-customers .all-customers .customer-card .customer-btn {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #0674B7;
    line-height: 1; }
    @media (max-width: 575.98px) {
      #page-customers .all-customers .customer-card .customer-btn {
        width: 80%;
        bottom: 10px; } }
    @media (max-width: 991.98px) {
      #page-customers .all-customers .customer-card .customer-btn {
        transform: none !important;
        opacity: 1 !important;
        visibility: visible !important;
        font-size: 8px; } }
    #page-customers .all-customers .customer-card .customer-btn:after {
      content: "";
      background: url("../img/icon/small-right-arrow.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 18px;
      height: 10px;
      margin-left: 25px; }
      @media (max-width: 991.98px) {
        #page-customers .all-customers .customer-card .customer-btn:after {
          height: 6px; } }

#page-news-archive .filter .filter-container {
  display: flex;
  justify-content: center; }
  @media (max-width: 991.98px) {
    #page-news-archive .filter .filter-container {
      flex-direction: column; } }
  #page-news-archive .filter .filter-container .filter-item {
    cursor: pointer; }
    @media (max-width: 991.98px) {
      #page-news-archive .filter .filter-container .filter-item {
        text-align: center; } }
    #page-news-archive .filter .filter-container .filter-item.active {
      color: #0674B7; }
    #page-news-archive .filter .filter-container .filter-item:hover {
      color: #0674B7; }

#page-news-archive .item-news {
  padding: 30px 0;
  border-bottom: 1px solid rgba(146, 146, 146, 0.8); }
  @media (max-width: 991.98px) {
    #page-news-archive .item-news .item-news-btn {
      justify-content: flex-start; } }

@media (max-width: 991.98px) {
  #page-global-coverage .filter-container {
    flex-direction: column; } }

#page-global-coverage .filter-container .filter-item {
  display: flex;
  align-items: center; }
  @media (max-width: 575.98px) {
    #page-global-coverage .filter-container .filter-item:not(:last-child) {
      margin-bottom: 20px; } }
  #page-global-coverage .filter-container .filter-item div {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }

#page-global-coverage .map-container {
  height: calc( 100vh - 200px);
  width: 100vw;
  overflow: hidden;
  position: relative; }
  @media (max-width: 991.98px) {
    #page-global-coverage .map-container {
      height: 400px; } }
  #page-global-coverage .map-container #map {
    height: 100%;
    width: 100%;
    z-index: 0; }
  #page-global-coverage .map-container .popup {
    display: flex;
    align-items: center;
    padding: 0 150px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
    height: 100%;
    background: #0674B7;
    color: #FFFFFF;
    transform: translateX(100%); }
    @media (max-width: 991.98px) {
      #page-global-coverage .map-container .popup {
        padding: 0 50px;
        opacity: .9;
        width: 100%; } }
    #page-global-coverage .map-container .popup .close {
      cursor: pointer;
      height: 14px;
      width: 14px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 45px;
      right: 45px; }

#page-404 {
  height: 100vh; }
  #page-404 .page-inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  #page-404:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 100%;
    background: #2574B7;
    mix-blend-mode: multiply;
    top: 0; }
    @media (max-width: 991.98px) {
      #page-404:before {
        width: 100%; } }

#page-services .logo {
  max-width: 100%;
  width: auto; }

/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn-full {
  display: inline-block;
  width: 100%; }

.btn-arrow, .newsletter-form .gform_footer input {
  border: 0px;
  font-weight: 700;
  position: relative;
  padding: 18px 20px 15px;
  background: url("../img/icon/right-arrow.svg") no-repeat right 30px center;
  background-size: auto;
  transition: all 0.3s ease; }
  .btn-arrow::before, .newsletter-form .gform_footer input::before, .btn-arrow::after, .newsletter-form .gform_footer input::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 0.3s ease; }
  .btn-arrow::before, .newsletter-form .gform_footer input::before {
    transform: translateX(-2px) translateY(2px);
    border-left: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9; }
  .btn-arrow::after, .newsletter-form .gform_footer input::after {
    transform: translateX(2px) translateY(-2px);
    border-right: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9; }
  .btn-arrow:hover, .newsletter-form .gform_footer input:hover {
    background-position: right 20px center; }
    .btn-arrow:hover::before, .newsletter-form .gform_footer input:hover::before, .btn-arrow:hover::after, .newsletter-form .gform_footer input:hover::after {
      opacity: 1;
      transform: translateX(0) translateY(0); }
  @media (max-width: 991.98px) {
    .btn-arrow, .newsletter-form .gform_footer input {
      border: 1px solid #D9D9D9; }
      .btn-arrow::before, .newsletter-form .gform_footer input::before, .btn-arrow::after, .newsletter-form .gform_footer input::after {
        display: none; } }
  @media (max-width: 767.98px) {
    .btn-arrow, .newsletter-form .gform_footer input {
      background: #FFFFFF;
      text-align: center; } }

.btn-custom {
  color: #0674B7;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center; }
  .btn-custom:hover {
    color: #0674B7; }
    .btn-custom:hover:after {
      transform: translateX(-10px); }
  .btn-custom:after {
    content: "";
    width: 24px;
    height: 14px;
    margin-left: 60px;
    transition: .3s all;
    background-image: url("../img/icon/right-arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }

/*
|--------------------
|  Smooth Scrollbar
|--------------------
*/
/*
|
| Scroll area
|--------------
*/
.scroll-area .scrollbar-track.scrollbar-track-y {
  width: 4px !important; }
  .scroll-area .scrollbar-track.scrollbar-track-y .scrollbar-thumb {
    width: 100% !important;
    background: #000000 !important; }

.swiper-pagination-bullet {
  background-color: transparent !important;
  border: 1px solid #0674B7 !important; }
  .swiper-pagination-bullet-active {
    background-color: #0674B7 !important; }

.form .gform_body .gform_fields {
  display: flex;
  flex-wrap: wrap; }

.form .gform_body .gfield_required {
  display: none; }

.form .gform_body .materialize-form-group {
  position: relative;
  margin-top: 30px; }
  .form .gform_body .materialize-form-group label {
    color: rgba(146, 146, 146, 0.8);
    font-weight: 300;
    position: absolute;
    bottom: 10px;
    left: 15px;
    margin-bottom: 10px; }
  .form .gform_body .materialize-form-group input {
    padding-left: 15px;
    border: 0;
    border-bottom: 1px solid rgba(146, 146, 146, 0.8); }

.form .gform_body .ginput_container_textarea textarea {
  border: 1px solid rgba(146, 146, 146, 0.8);
  padding: 15px; }

.form .gform_body .ginput_container_textarea textarea::placeholder {
  color: rgba(146, 146, 146, 0.8); }

.form .gform_body .consent {
  display: flex;
  width: 100%;
  margin-top: 25px; }
  .form .gform_body .consent .gfield_label {
    display: none; }
  .form .gform_body .consent .gfield_checkbox li {
    display: flex;
    align-items: center;
    padding: 0 15px;
    position: relative; }
    @media (max-width: 991.98px) {
      .form .gform_body .consent .gfield_checkbox li {
        flex-direction: column; } }
    .form .gform_body .consent .gfield_checkbox li input {
      position: absolute;
      cursor: pointer;
      height: 0;
      width: 0;
      opacity: 0; }
    .form .gform_body .consent .gfield_checkbox li label {
      cursor: pointer;
      padding-left: 50px;
      width: 50%; }
      @media (max-width: 991.98px) {
        .form .gform_body .consent .gfield_checkbox li label {
          width: 100%; } }
      .form .gform_body .consent .gfield_checkbox li label:before {
        content: "";
        position: absolute;
        top: 0;
        height: 24px;
        width: 24px;
        transition: all 0.3s ease;
        border-radius: 4px;
        border: 1px solid rgba(146, 146, 146, 0.8); }
      .form .gform_body .consent .gfield_checkbox li label:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 17px;
        height: 20px;
        width: 20px;
        transition: all 0.3s ease;
        transform: scale(0);
        background: rgba(146, 146, 146, 0.8);
        border-radius: 4px; }
      .form .gform_body .consent .gfield_checkbox li label:before {
        left: 15px; }
    .form .gform_body .consent .gfield_checkbox li input:checked ~ label::after {
      transform: scale(1); }
    .form .gform_body .consent .gfield_checkbox li input:checked ~ label {
      font-weight: 300; }
  .form .gform_body .consent .gfield_description {
    border: 0;
    font-size: 12px;
    margin-left: 10px;
    padding: 0;
    width: 50%; }

.form .gform_footer {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  float: right;
  transform: translateY(-150%);
  cursor: pointer; }
  @media (max-width: 991.98px) {
    .form .gform_footer {
      transform: translateY(0);
      justify-content: center;
      width: 100%;
      float: none; } }
  .form .gform_footer:after {
    content: "";
    background: url("../img/icon/small-right-arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 10px;
    margin-left: 25px;
    transition: .3s all; }
  .form .gform_footer:hover:after {
    transform: translateX(-10px); }
  .form .gform_footer input[type=submit] {
    -webkit-appearance: none;
    cursor: pointer;
    color: #0674B7;
    text-transform: uppercase;
    font-weight: 700;
    background: #FFFFFF;
    border: 0; }
    @media (max-width: 991.98px) {
      .form .gform_footer input[type=submit] {
        margin: 0;
        width: auto; } }

.form .gform_validation_error label {
  transform: translateY(-10px);
  font-size: 12px !important; }

.form .gfield_error {
  background: transparent !important;
  padding: 0 !important;
  border: 0 !important;
  padding-right: 16px !important; }
  .form .gfield_error label {
    color: red !important; }
  .form .gfield_error input {
    border: 0 !important;
    border-bottom: 1px solid red !important; }
  .form .gfield_error .validation_message {
    display: none; }

.form .validation_error {
  display: none; }

.nl-socials .newsletter-container,
.nl-socials .socials-container {
  margin: 0 auto; }

@media (max-width: 991.98px) {
  .nl-socials .title {
    text-align: center; } }

.nl-socials .socials-container {
  display: flex;
  align-items: center; }
  .nl-socials .socials-container .item-img {
    max-width: 100%; }
    @media (max-width: 991.98px) {
      .nl-socials .socials-container .item-img {
        max-width: 50%; } }
  .nl-socials .socials-container .socials {
    text-align: left; }
    @media (max-width: 991.98px) {
      .nl-socials .socials-container .socials {
        text-align: center; } }
    @media (max-width: 991.98px) {
      .nl-socials .socials-container .socials-items {
        text-align: center; } }
    .nl-socials .socials-container .socials .item-link {
      margin-right: 40px; }
      @media (max-width: 991.98px) {
        .nl-socials .socials-container .socials .item-link {
          margin: 0 20px; } }
      @media (max-width: 767.98px) {
        .nl-socials .socials-container .socials .item-link {
          margin: 0 10px; } }
      .nl-socials .socials-container .socials .item-link::hover {
        opacity: 0.8;
        color: #FFFFFF; }

.newsletter-form .gform_confirmation_wrapper {
  color: #FFFFFF;
  text-align: center; }

@media (max-width: 991.98px) {
  .newsletter-form .gform_wrapper {
    margin: 0 !important; } }

.newsletter-form .gform_body,
.newsletter-form .gform_footer {
  width: 49% !important;
  display: inline-block; }

@media (max-width: 991.98px) {
  .newsletter-form .gform_body {
    width: 60% !important; } }

.newsletter-form .gform_body li {
  padding-right: 0 !important; }
  .newsletter-form .gform_body li input {
    height: 100px;
    padding: 5px 10px !important;
    background-color: #0674B7;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    text-align: left;
    font-family: "Gotham", sans-serif;
    text-transform: uppercase;
    vertical-align: middle; }
    @media (max-width: 991.98px) {
      .newsletter-form .gform_body li input {
        height: 70px; } }
    @media (max-width: 767.98px) {
      .newsletter-form .gform_body li input {
        height: 50px;
        font-size: 12px;
        text-transform: inherit; } }
    .newsletter-form .gform_body li input::placeholder {
      color: #FFFFFF;
      text-align: left;
      font-family: "Gotham", sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      opacity: 1;
      transition: all 0.3s ease; }
      @media (max-width: 767.98px) {
        .newsletter-form .gform_body li input::placeholder {
          font-size: 12px; } }
    .newsletter-form .gform_body li input:focus::placeholder {
      opacity: 0.2; }

.newsletter-form .gform_body .gfield_label {
  display: none !important; }

.newsletter-form .gform_footer {
  margin: 0 0 0 -5px !important;
  padding: 0 0 0 !important;
  vertical-align: middle; }
  @media (max-width: 991.98px) {
    .newsletter-form .gform_footer {
      width: 39% !important; } }
  .newsletter-form .gform_footer input {
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    margin: 0 !important;
    width: 100% !important;
    height: 100px;
    font-size: 14px;
    letter-spacing: 3px; }

@-moz-document url-prefix() {
  .newsletter-form .gform_footer input {
    border-bottom: 3px solid #FFFFFF; } }
    @media (max-width: 991.98px) {
      .newsletter-form .gform_footer input {
        height: 70px; } }
    @media (max-width: 767.98px) {
      .newsletter-form .gform_footer input {
        height: 50px;
        padding: 0;
        font-size: 12px !important; } }

@media (max-width: 991.98px) {
  .page-bottom p {
    flex-direction: column;
    text-align: center; } }

.burger {
  width: 40px;
  height: 20px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 0;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 1000; }
  @media (max-width: 1299.98px) {
    .burger {
      display: flex; } }
  @media (max-width: 767.98px) {
    .burger {
      right: 20px; } }
  .burger span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #0674B7;
    border-radius: 1px;
    transition: all 0.3s ease; }
  .burger.open .one {
    transform: rotate(-45deg) translateY(12px); }
  .burger.open .two {
    transform: scale(0); }
  .burger.open .three {
    transform: rotate(45deg) translateY(-12px); }

.lang-switch {
  z-index: 10;
  top: 0;
  right: 0;
  padding-right: 10px;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-transform: lowercase; }
  .lang-switch .wpml-ls-legacy-list-horizontal {
    padding: 7px 0; }
  .lang-switch .wpml-ls-item {
    padding: 7px;
    letter-spacing: 1px;
    border: 1px solid #0674B7;
    opacity: .4;
    transition: .3s opacity; }
    .lang-switch .wpml-ls-item:nth-child(2), .lang-switch .wpml-ls-item:nth-child(3) {
      margin-left: -1px; }
    @media (max-width: 991.98px) {
      .lang-switch .wpml-ls-item a {
        padding: 5px 5px; } }
    .lang-switch .wpml-ls-item:hover {
      opacity: 1; }
      .lang-switch .wpml-ls-item:hover a {
        color: #0674B7; }
  .lang-switch .wpml-ls-current-language {
    opacity: 1; }

.lang-switch-responsive {
  display: none; }
  .lang-switch-responsive .wpml-ls-item {
    padding: 7px;
    letter-spacing: 1px;
    border: 1px solid #0674B7;
    opacity: .4;
    text-transform: lowercase; }
    .lang-switch-responsive .wpml-ls-item:nth-child(2), .lang-switch-responsive .wpml-ls-item:nth-child(3) {
      margin-left: -1px; }
  .lang-switch-responsive .wpml-ls-current-language {
    opacity: 1; }
