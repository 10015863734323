/*
|--------------------
|     PAGE HOME
|--------------------
*/
#page-home{
    .banner{
        padding: 0 !important;  
    }
	.home-footer{
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}
/*
|
| Banner
|---------
*/
#fp-nav{
    right: 50px !important;
    top: 60% !important;

    z-index: 3;

	@include media-breakpoint-down(md) {
		display: none;
	}

	ul{
		position: relative;

		li:not(:last-child){
			margin-bottom: 28px;
		}

		li{
            position: relative;
            
            height: 10px;
            width: 10px;

			&:last-child{
				&:before{
					display: none;
				}
			}

			&:before{
				content: "";
				position: absolute;

				top: 100%;
				left: 50%;

				transform: translateX(-50%);

				width: 1px;
				height: 42px;

				background-color: $blue;
			}
		}

		li{
			a{
				position: relative;

				&:after{
					content: "";
					position: absolute;

					top: 50%;
					left: 50%;

					transform: translate(-50%, -50%) scale(0);

					width: 32px;
					height: 32px;

					border-radius: 50%;
					border: 2px solid $blue;

					transition: 1s all;
				}

				border: 2px solid $blue;
				border-radius: 50%;

				background: transparent;
				transition: 1s all;

				span{
					display: none;
				}

				&.active{
					background-color: $blue;

					&:after{
						transform: translate(-50%, -50%) scale(1);
					}

				}
			}


		}
	}

	&.white{
		ul{
			li{
				&:before{
					background-color: $white;
				}
				&:after{
					background-color: $white;
				}

				a{
					border: 2px solid $white;
					&:after{
						border: 2px solid $white;
					}
				}
			}
		}
	}
}

.banner{
	height: 820px;
	@include media-breakpoint-down(sm) {
        height: auto;
	}
	.overlay{
		position: relative;
		&::before,
		&::after{
			content: "";
			height: 100%;
			position: absolute;
			top: 0;
		}
		&::before{
			z-index: 1;
			width: 20%;
			right: 0;
			background-color: $border-grey;
			@include media-breakpoint-down(sm) {
		        width: 0%;
		    }
		}
		&::after{
			z-index: 3;
			width: 80%;
			left: 0;
			background-color: $blue;
			mix-blend-mode: multiply;
			@include media-breakpoint-down(sm) {
		        width: 100%;
		    }
		}
		.container{
			@include media-breakpoint-down(sm) {
		        max-width: 100%;
		    }
		    .banner-bg{
				position: relative;
				z-index: 2;

				background-size: inherit;
				background-position: center;
				background-repeat: no-repeat;

				@include media-breakpoint-down(sm) {
			        background-size: cover;
			    }
			}
		}
	}

	.text-container{
		position: absolute;
		display: flex;
		align-items: center;
		top: 50%;
		transform: translateY(-50%);
		@include media-breakpoint-down(sm) {
	        text-align: center;
	    }
	    .logo{
	    	@include media-breakpoint-down(sm) {
		        width: 80%;
		    }
		}
		h1{
			font-size: 46px !important;
			@include media-breakpoint-down(sm) {
		        font-size: 32px !important;
		    }
		}
	}


}


/*
|
| Section stand for
|--------------------
*/
.stand-for{
	background-size: auto 120%;
	background-position: left center;
	background-repeat: no-repeat;
	@include media-breakpoint-down(sm) {
        background-image: none;
    }
}



/*
|
| Section video
|----------------
*/
.video{
	video{
		width: 100%;
		height: 740px;
		@include media-breakpoint-down(md) {
	        height: 480px;
	    }
	}
	.plyr__poster{
		background-size: cover;
	}
	.plyr__control{
		&.plyr__tab-focus{
			box-shadow: 0 0 0 0 rgba(26, 175, 255, 0.0) ;
		}
		&.plyr__control--overlaid{
			width: 150px;
			height: 150px;
			border: 1px solid $white;
			background-color: transparent;
			svg{
				left: auto;
				margin: 0 auto;
			}
		}
	}
	.plyr--stopped.plyr--video {
	  	.plyr__controls {
		    opacity: 0;
		    pointer-events: none;
		}
		&.plyr--init-play {
		    .plyr__controls {
		      	opacity: 1;
		      	pointer-events: inherit;
		    }
		}
	}
}


/*
|
| Section vision
|-----------------
*/
.vision{
	.item{
		padding: 20px;
		background-color: transparent;
		transition: all 0.3s ease;
		@include media-breakpoint-down(sm) {
	        padding: 20px;
	    }

		&-img-container{
			width: 110px;
			height: 110px;
			margin: 0 auto;
			position: relative;
			background-color: $white;
			border-radius: 100%;
			transition: all 0.3s ease;
			&>div{
				position: absolute;
				top: 0;
				left: 0;
				transition: all 0.3s ease;
			}
			.not-hover{
				opacity: 1;
			}
			.hover{
				opacity: 0;
			}
		}
		&-title{
			line-height: $line-height-lg;
		}
		&-text{
			padding: 0 20px;
		}
		&-title,
		&-text{
			transition: all 0.3s ease;
		}

		&:hover{
			background-color: $white;
			.item-img-container{
				background-color: $blue;
				.not-hover{
					opacity: 0;
				}
				.hover{
					opacity: 1;
				}
			}
			.item-title,
			.item-text{
				color: $blue;
			}
			svg{
				fill: $white;
			}
		}

	}
}



/*
|
| Section slider
|-----------------
*/
.slider{

	.full-swiper{
		padding: 0 10% 0 0;
		.swiper-slide{
			overflow: hidden;
		}
    }
    
	.txt-swiper{
		.swiper-slide{
			display: none;
		}
		.fs-lg{
			@include media-breakpoint-down(md) {
				font-size: 18px !important;
			}
		}
    }

    .swiper-pagination{
        position: relative;
    }

	.item-img{
		height: 700px;
		@include media-breakpoint-down(md) {
	        height: 340px;
	    }
	}
}



/*
|
| Section key numbers
|----------------------
*/
.key-numbers{
	.item{
		border-right: 1px solid $border-grey;
		&:last-child{
			border-right: 0;
		}
		@include media-breakpoint-down(md) {
	        &:nth-child(even){
	        	border-right: 0;
	        }
	    }
	    @include media-breakpoint-down(sm) {
	        border-right: 0;
	    }
	}

}



/*
|
| Section key benefits
|-----------------------
*/
.key-benefits{
	.item-container{
		border-top: 1px solid $border-grey;
		cursor: pointer;
		.item-title{
			display: flex;
			justify-content: space-between;
			align-items: center;
			svg{
				color: $black;
			}
		}
		.item-text{
			display: none;
		}
	}
}


/*
|
| Section references
|---------------------
*/

.references{
	.swiper-wrapper{
		padding: 0 5%;
		.swiper-slide{
			overflow: hidden;
		}
	}
	.logo{
        height: 120px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        
        cursor: pointer;
        
        @include media-breakpoint-down(sm) {
            max-height: 90px;
        }

        @include media-breakpoint-down(xs) {
            max-height: 60px;
        }
		
	}
}
