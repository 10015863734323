.lang-switch{
    z-index: 10;
	top: 0;
	right: 0;
	padding-right: 10px;
	width: auto;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	text-transform: lowercase;
    .wpml-ls-legacy-list-horizontal{
    	padding: 7px 0;
    }
    .wpml-ls-item{
		padding: 7px;
		letter-spacing: 1px;
		border: 1px solid $blue;
		opacity: .4;

		transition: .3s opacity;
		&:nth-child(2), &:nth-child(3){
			margin-left: -1px;
		}

        a{
            @include media-breakpoint-down(md) {
                padding: 5px 5px;
            }
        }

		&:hover{
			opacity: 1;
			a{
				color: $blue;
			}
		}
    }
    .wpml-ls-current-language{
		opacity: 1;
    }
}
.lang-switch-responsive{
	display: none;
    .wpml-ls-item{
		padding: 7px;
		letter-spacing: 1px;
		border: 1px solid $blue;
		opacity: .4;
		text-transform: lowercase;
		&:nth-child(2), &:nth-child(3){
			margin-left: -1px;
		}
    }
    .wpml-ls-current-language{
		opacity: 1;
    }
}
