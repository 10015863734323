.page-template-cms{
	header{
		position: absolute!important;
	// 	top: 0;
	// 	background-color: transparent;
	// 	nav > ul > li > a{
	// 		color: $white;
	// 	}
	}
	.banner-cms{
		height: 100vh;
		width: 100%;
		background-color: $blue;
		display: flex;
		justify-content: center;
		align-items: center; 

		.scroll-down{
			position: absolute;
			bottom: 80px;
			width: 100%;
			
			@include media-breakpoint-down(md) {
		        bottom: 50px;
		    }
		    @include media-breakpoint-down(sm) {
		        bottom: 40px;
		    }
		    @include media-breakpoint-down(xs) {
		        bottom: 30px;
		    }
		}
	}

	.content{
		h1, h2, h3, h4, h5, h6{
			color: $black;
		}
		h3{
			margin-bottom: $md;
		}
		ul{
			margin-left: 40px;
		}
	}
}