.nl-socials{
	.newsletter-container,
	.socials-container{
		margin: 0 auto;
	}
	.title{
		@include media-breakpoint-down(md) {
	        text-align: center;
	    }
	}
	.socials-container{
		display: flex;
		align-items: center;
		.item-img{
			max-width: 100%;

			@include media-breakpoint-down(md) {
				max-width: 50%;
			}
		}

		.socials{
			text-align: left;
			@include media-breakpoint-down(md) {
		        text-align: center;
		    }
		    &-items{
		    	@include media-breakpoint-down(md) {
			        text-align: center;
			    }
		    }
			.item-link{
				margin-right: 40px;
				@include media-breakpoint-down(md) {
			        margin: 0 20px;
			    }
				@include media-breakpoint-down(sm) {
			        margin: 0 10px;
			    }
				&::hover{
					opacity: 0.8;
					color: $white;
				}
			}
		}
	}
		
}