.burger{
	width: 40px;
	height: 20px;
	position: absolute;
	top: 50%;
	margin-top: -10px;
	right: 0;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	z-index: 1000;
	@include media-breakpoint-down(lg) {
		display: flex;
	}
	@include media-breakpoint-down(sm) {
		right: 20px;
	}
	span{
		display: block;
		width: 100%;
		height: 2px;
		background-color: $blue;
		border-radius: 1px;
        transition: all 0.3s ease;
	}
	&.open{
		.one{
            transform: rotate(-45deg) translateY(12px);
		}
		.two{
			transform: scale(0);
		}
		.three{
            transform: rotate(45deg) translateY(-12px);
		}
	}
}