@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
/* IE10+ CSS styles go here */
	#page-home{
		.banner{
			background-color: $blue;
			.text-container{
				position: relative;
			}
		}
		.overlay{
			display: none;
		}
		.key-benefits{
			.item-container{
				.text{
					display: block;
				}
			}
		}
	}
}