/*
|--------------------
|      HEADER
|--------------------
*/

.c-default{
	cursor: default;
}

header{

	position: fixed;
	top: 0;

	@include media-breakpoint-down(lg) {
		transform: none !important;
		top: 0 !important;
	}

	.btn-menu{
		color: $dark-grey;
		transition: .3s color;

		&:hover{
			color: $blue;
		}
	}

	.contact{
		display: block;
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.contact-responsive{
		display: none;
		@include media-breakpoint-down(lg) {
			display: block;
		}
	}

	.logo-container{
		position: absolute;

	    left: 50%;
	    transform: translateX(-50%);

		@include media-breakpoint-down(lg) {
			top: 50%;
			transform: translate(-50%, -50%);

			width: 64px;
        }

        width: 100px;
        
        img {
            width: 100%;
        }

	}

	.current-menu-item{
		color: $blue;
		font-weight: 700;
		position: relative;

        a{
            color: inherit;
        }

		&:before{
            content: "";
            
            position: absolute;
            
			bottom: 0;
            right: 0;
            
			width: 100%;
            height: 5px;
            
			background-color: rgba(146, 146, 146, 0.8);
			transform: skewX(20deg);
		}
	}

	nav.desktop{
		margin-top: 40px;

		@include media-breakpoint-down(lg) {
			display: none;
		}

		ul{
			margin-bottom: 0;
			display: flex;
			justify-content: center;

			li{
				position: relative;

                margin-left: 10px;
                padding: 5px 20px 5px 15px;

                .nav-drop {
                    display: none;

					position: absolute;
					
					width: 250px;

                    left: 50%;
                    transform: translateX(-50%);

                    padding: 10px 20px;

                    li {
                        display: inline-block;
                        position: relative;
                        z-index: 3;

                        &:after {
                            display: none;
                        }
                    }
                }

                &:hover {
                    .nav-drop {
                        display: block;
                    }
                }

            }

			> li:not(:last-child){

				&:after{
					content: "";
					position: absolute;

					height: 100%;
					width: 1px;

					right: 0;

					top: 0;
					transform: rotate(-20deg);
					transform-origin: center;

					background-color: $dark-grey;
                }

            }
		}
	}

	nav.responsive{
		display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 100%;
        flex-direction: column;
	    justify-content: center;
	    align-items: center;
        background-color: $white;
        transition: all 0.3s ease;
        z-index: 103;

        .arrow-menu{
            height: 12px;
            width: 12px;

            position: absolute;
            right: 0;
            
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        @include media-breakpoint-down(lg) {
			display: flex;
		}

        &.open{
        	left: 0%;
        	transition: all 0.3s ease;
        }

		.logo-container-responsive{
			position: relative;

			left: 0;
			transform: none;
		}

		>ul {
			display: flex;
			flex-direction: column;
			justify-content: center;
            align-items: center;
			.menu-item-has-children{
				text-align: center;
			}
			.nav-drop{
                display: block;

                overflow: hidden;

				list-style-type: none;
				text-align: center;
				a{
					font-size: 14px;
				}
			}
			a, .contact-responsive{
				color: $blue;

				@include media-breakpoint-down(sm) {
					font-size: $font-size-md;
				}
			}
		}

		.logo-container-responsive
		{
            display: block;
            
            position: absolute;

            bottom: 50px;
            top: auto;
            left: auto;

            margin: auto;
		}
	}
}
