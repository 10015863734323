/*
|--------------------
|       Contact
|--------------------
*/

#page-contact{
    /*
    |
    | Section contact
    |------------------ 
    */
    .head{
        .left-side{
            @each $breakpoint,
            $max-width in $container-max-widths {
                @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                    padding-left: calc((100vw - #{$max-width}) / 2);
                    
                }
            }

            .btn-container{
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    align-items: center;

                    a{
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    .adresses-container{
        @include media-breakpoint-down(md) {

            .adress-item:not(last-child){
                margin-bottom: 40px;
            }

            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }


    


}
