#page-news-archive {

    .filter {
        .filter-container {

            display: flex;
            justify-content: center;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

            .filter-item {
                cursor: pointer;

                @include media-breakpoint-down(md) {
                    text-align: center;
                } 

                &.active {
                    color: $blue;
                }

                &:hover {
                    color: $blue;
                }
            }
        }
    }

    .item-news {

        padding: 30px 0;
        border-bottom: 1px solid $grey;

        .item-news-btn{

            @include media-breakpoint-down(md) {
                justify-content: flex-start;
            }

        }

    }


}