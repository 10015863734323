#page-404{
    height: 100vh;

    .page-inner{
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:before {
        content: "";

        position: absolute;

        width: 80%;
        height: 100%;

        background: #2574B7;

        mix-blend-mode: multiply;

        top: 0;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}