/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/

//BOLD
@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Bold.eot');
    src: url('../fonts/gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Bold.woff') format('woff'),
        url('../fonts/gotham/Gotham-Bold.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

//MEDIUM
@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Medium.eot');
    src: url('../fonts/gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Medium.woff') format('woff'),
        url('../fonts/gotham/Gotham-Medium.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

//NORMAL
@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Light.eot');
    src: url('../fonts/gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Light.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Light.woff') format('woff'),
        url('../fonts/gotham/Gotham-Light.ttf') format('truetype'),
        url('../fonts/gotham/Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

//ROBOTO LIGHT
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Medium.eot');
    src: url('../fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Medium.woff') format('woff'),
        url('../fonts/roboto/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}




