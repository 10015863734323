/*
|--------------------
|      CONTENT
|--------------------
*/
.page-offset{
    padding-top: 162px;
    @include media-breakpoint-down(lg) {
        padding-top: 102px;
    }
}

h1{
    font-weight: 700 !important;
}


/*
|
| Helpers
|-----------
*/
.mx-auto{ 
    margin: auto;
}


/*
|
| Banner
|---------
*/
.banner{

    &:before{
        content: "";

        position: absolute;

        width: 80%;
        height: 100%;

        background: #2574B7;

        mix-blend-mode: multiply;

        top: 0;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    @extend .bg-cover;

    position: relative;

    width: 100%;
    height: auto !important;

    padding: 150px 0;

    .item-content{
        width: 100%;
    }
}

.single{
    .banner{
        height: 480px;
    }
}



/*
|
| Custom card
|--------------
*/
.custom-card{
    .item-img{
        @extend .bg-cover;
        width: 100%;
        height: 200px;
    }

    .item-content{
        padding: 15px 30px;
        border: 1px solid $border-color;
    }
}


/*
|
| Slider Links
|--------------
*/
.slider-links{
    background: $blue;

    .swiper-container{
        padding-right: 250px;

        @include media-breakpoint-down(md) {
            padding-right: 0;
        }

        .arrow-next, .arrow-prev{
            position: absolute;

            height: 30px;
            width: 30px;

            bottom: 20%;

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            z-index: 1;
            cursor: pointer;

            &.button-disabled{
                opacity: 0;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

        }

        .arrow-next{
            right: 250px;
        }

        .arrow-prev{
            left: 50px;

            transform: rotate(-180deg);
        }

    }
    .swiper-slide{
        position: relative;

        height: auto !important;

        display: flex;
        align-items: center;

        .overlay{
            position: absolute;

            z-index: 3;

            width: 100%;
            height: 100%;

            top: 0;
            left: -210px;

            background: $blue;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &:before{
            content: "";

            z-index: 1;

            position: absolute;

            width: 100%;
            height: 100%;

            top: 0;
            left: 0;

            background: #2574B7;

            mix-blend-mode: multiply;
        }

        .swiper-slide-inner {
            position: relative;

            z-index: 5;

            // padding: 200px 100px;
            padding-top: 200px;
            padding-bottom: 200px;

            @extend .container;

            @include media-breakpoint-up(lg) {
                max-width: 100%;

                @each $breakpoint,
                $max-width in $container-max-widths {
                    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                        padding: {
                            left: calc((100vw - #{$max-width}) / 2);
                        }
                    }
                }
            }
            
            @include media-breakpoint-down(md) {
                padding: 50px 15px;
            }
        }
    }

}


.cms{
    /*
    |
    | Lists
    |--------------
    */
    ul{
        list-style: none;
        li{            
            display : flex;
            
            &:before{
                content: "•";

                margin-right: 5px;

                border-radius: 50%;
            }
        }
    }
}



/*
|
| Loader
|----------
*/
.loader {
    display: none;

  margin: 10px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid mix(white,#177FC3,80%);
  border-right: 2px solid mix(white,#177FC3,80%);
  border-bottom: 2px solid mix(white,#177FC3,80%);
  border-left: 2px solid #177FC3;
  -webkit-animation: load8 .5s infinite linear;
  animation: load8 .5s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width:30px;
  height: 30px;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}




/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
    display: none;
    position: fixed;
    z-index: 200;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 0;

    .item-close {
        font-size: 24px;
        cursor: pointer;
    }

    .btn{
        border: 1px solid transparent;
        background-color: $blue;
        color: $white;
        &:hover{
            border: 1px solid $blue;
            background-color: $white;
            color: $blue;
        }
    }


    @include media-breakpoint-down(sm) {
        padding: 20px 0;
        font-size: 12px;
    }

    @include media-breakpoint-down(xs) {
        padding: 10px 0 20px 0;

        .item-close {
            font-size: 18px;
        }
    }
}


/*
|
| Width & Height 100%
|----------------------
*/
.w-100{
    width: 100%;
}
.h-100{
    height: 100%;
}



/*
|
| Text alignment
|----------------
*/
.text-center{
    text-align: center;
}

.text{
    ul{
        margin-left: 20px;
    }
}
.strong{
    font-weight: bold;
}

/*
|
| Links
|--------
*/
a{
    text-decoration: none;
}
