#page-global-coverage{

    .filter-container{
        @extend .d-f;
        @extend .jc-center;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .filter-item {

            display: flex;
            align-items: center;

            @include media-breakpoint-down(xs) {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }

            div {
                height: 20px;
                width: 20px;

                margin-right: 5px;

                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }


    .map-container{
        height: calc( 100vh - 200px);
        width: 100vw;

        overflow: hidden;

        position: relative;

        @include media-breakpoint-down(md) {
            height: 400px;
        }

        #map {

            height: 100%;
            width: 100%;

            z-index: 0;
        }

        .popup {
            display: flex;
            align-items: center;

            padding: 0 150px;

            position: absolute;

            right: 0;
            top: 0;

            width: 40%;
            height: 100%;

            background: $blue;
            color: $white;

            transform: translateX(100%);

            @include media-breakpoint-down(md) {
                padding: 0 50px;

                opacity: .9;

                width: 100%;
            }

            .close{
                cursor: pointer;

                height: 14px;
                width: 14px;

                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                position: absolute;
                top: 45px;
                right: 45px;
            }
        }
    }



}
