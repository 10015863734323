.newsletter-form{

	.gform_confirmation_wrapper{

		color: $white;
		text-align: center;
    }
    
	.gform_wrapper{

		@include media-breakpoint-down(md) {
	        margin: 0!important;
        }
        
    }
    
	.gform_body,
	.gform_footer{

		width: 49%!important;
        display: inline-block;
        
    }
    
	.gform_body{

		@include media-breakpoint-down(md) {
	        width: 60%!important;
        }
        
		li{

            padding-right: 0!important;
            
			input{
                height: 100px;
                padding: 5px 10px !important;
                
				background-color: $blue;
                border: 1px solid $white;
                
				color: $white;
				text-align: left;
				font-family: $font-family-custom;
                text-transform: uppercase;
                
                vertical-align: middle;

				@include media-breakpoint-down(md) {
			        height: 70px;
                }
                
			    @include media-breakpoint-down(sm){
                    height: 50px;
                    
			    	font-size: 12px;
			    	text-transform: inherit;
			    }

			    &::placeholder{
					color: $white;
					text-align: left;
					font-family: $font-family-custom;
					font-size: 14px;
                    text-transform: uppercase;
                    
                    opacity: 1;
                    
                    transition: all 0.3s ease;
                    
					@include media-breakpoint-down(sm){
				    	font-size: 12px;
				    }
				}

			    &:focus::placeholder{
			    	opacity: 0.2;
			    }
			}
		}
		.gfield_label{
			display: none!important;
		}
	}

	.gform_footer{
		margin: 0 0 0 -5px!important;
        padding: 0 0 0!important;
        
        vertical-align: middle;
        
		@include media-breakpoint-down(md) {
	        width: 39%!important;
        }
        
		input{
			@extend .btn;
            @extend .btn-arrow;
            
			border: 1px solid $white;
			background-color: $white;
			
			margin: 0!important;
			width: 100%!important;
			height: 100px;

			font-size: 14px;

			@-moz-document url-prefix() {
			  border-bottom: 3px solid $white;
			}

            letter-spacing: $letter-spacing-md;
            
			@include media-breakpoint-down(md) {
		        height: 70px;
            }
            
		    @include media-breakpoint-down(sm){
		    	height: 50px;
		    	padding: 0;

		    	font-size: 12px!important;
            }
            
		}
	}
	
}