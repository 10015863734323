#page-customers{

    h1{
        font-size: 48px;
    }

    .all-customers{

        .customer-card{
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            height: 371px;

            margin-bottom: 30px;

            @include media-breakpoint-down(md) {
                height: 220px;
            }

            .logo {
                height: 150px;
                width: 150px;

                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                @include media-breakpoint-down(md) {
                    height: 60px;
                    width: 90px;
                }
            }

            .customer-btn{
                position: absolute;

                bottom: 20px;

                display: flex;
                align-items: center;

                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
                color: $blue;
                line-height: 1;

                @include media-breakpoint-down(xs) {
                    width: 80%;
                    bottom: 10px;
                }

                @include media-breakpoint-down(md) {
                    transform: none !important;
                    opacity: 1 !important;
                    visibility: visible !important;

                    font-size: 8px;
                }

                &:after{
                    content: "";

                    background: url('../img/icon/small-right-arrow.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;

                    width: 18px;
                    height: 10px;

                    margin-left: 25px;

                    @include media-breakpoint-down(md) {
                        height: 6px;
                    }

                }
            }
        }
        
    }

}