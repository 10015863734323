/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| Colors declaration
|----------------------
|
*/
$black:           #000000;

$very-dark-grey:  #212121;
$dark-grey:       #424242;

$grey:            rgba(146,146,146,0.8);
$light-grey:      #F7F7F7;
$very-light-grey: #fafafa;
$border-grey:     #D9D9D9;

$white:           #FFFFFF;

$white-opacity:   rgba(255, 255, 255, 0.8);
$black-opacity:   rgba(0, 0, 0, 0.6);
$blue-opacity:    rgba(6, 116, 183, 0.5);

$blue : 	  	  #0674B7;


/*
|
| Assign colors
|---------------
|
*/
$border-color:                  $border-grey;
$text-color:                    $grey;
$default-background-overlay:    $blue-opacity;


/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
$colors : (
  //Required colors
  'black'          : $black,
  'very-dark-grey' : $very-dark-grey,
  'dark-grey'      : $dark-grey,
  'grey'           : $grey,
  'light-grey'     : $light-grey,
  'very-light-grey': $very-light-grey,
  'white'          : $white,
  'blue'		       : $blue,
  'border-grey'	   : $border-grey,
  'blue-opacity'   : $blue-opacity,
);



