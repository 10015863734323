/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn{

	&-full{
		display: inline-block;
		width: 100%;
	}
	&-arrow{
        border: 0px;
        
        font-weight: 700;

        position: relative;
        
        padding: 18px 20px 15px;
        
        background: url('../img/icon/right-arrow.svg') no-repeat right 30px center;
        
        background-size: auto;
        
		transition: all 0.3s ease;
		&::before,
		&::after{
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			opacity: 0;
			transition: all 0.3s ease;
		}
		&::before{
			transform: translateX(-2px) translateY(2px);
			border-left: 1px solid $border-grey;
			border-bottom: 1px solid $border-grey;
		}
		&::after{
			transform: translateX(2px) translateY(-2px);
			border-right: 1px solid $border-grey;
			border-top: 1px solid $border-grey;
		}
		&:hover{
			&::before,
			&::after{
				opacity: 1;
				transform: translateX(0) translateY(0);
			}
			background-position: right 20px center;
		}
		@include media-breakpoint-down(md) {
	        border: 1px solid $border-grey;
	        &::before,
	        &::after{
	        	display: none;
	        }
	    }
	    @include media-breakpoint-down(sm){
	    	background: $white;
	    	text-align: center;
	    }
	}
}


.btn-custom{
    color: $blue;
    text-transform: uppercase;
    font-weight: 700;

    position: relative;
    
    display: flex;
    align-items: center;

    &:hover{
        color: $blue;

        &:after{
            transform: translateX(-10px);
        }
    }

    &:after{
        content: "";

        width: 24px;
        height: 14px;

        margin-left: 60px;

        transition: .3s all;

        background-image: url('../img/icon/right-arrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}